import { VesselUUID } from "../../domain/VesselId";

export type HttpResponseError = "NotFound";

export type HttpNotFoundError = "HttpNotFoundError";

export interface RecalculatedVesselResult {
  id: VesselUUID;
  success: boolean;
  errorMessage?: HttpResponseError;
}

export function recalculateVesselResult({
  id,
  success,
  errorMessage
}: RecalculatedVesselResult): IncomingRecalculatedVesselResult {
  return {
    type: INCOMING_RECALCULATED_VESSEL_RESULT,
    id,
    success,
    errorMessage
  };
}

export const INCOMING_RECALCULATE_VESSEL = "INCOMING_RECALCULATE_VESSEL";

export interface IncomingRecalculateVessel {
  id: VesselUUID;
  type: typeof INCOMING_RECALCULATE_VESSEL;
}

export function recalculateVessel(id: VesselUUID) {
  return { type: INCOMING_RECALCULATE_VESSEL, id: id };
}

export const INCOMING_RECALCULATED_VESSEL_RESULT = "RECALCULATED_VESSEL_RESULT";

export interface IncomingRecalculatedVesselResult {
  id: VesselUUID;
  type: typeof INCOMING_RECALCULATED_VESSEL_RESULT;
  success: boolean;
  errorMessage?: string;
}

export type AdministrationAction =
  | IncomingRecalculateVessel
  | IncomingRecalculatedVesselResult;
