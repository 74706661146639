import { createRequest } from "../../util/Login";
import { VesselUUID } from "../../domain/VesselId";
import { RecalculatedVesselResult } from "./administration.actions";

export function recalculateVessel(
  id: VesselUUID
): Promise<RecalculatedVesselResult> {
  return fetch(
    `/api/vessels/${encodeURIComponent(id)}/recalculate`,
    createRequest("GET")
  ).then(response => {
    if (response.status === 200) {
      return Promise.resolve({ id, success: true, errorMessage: undefined });
    } else {
      return response
        .json()
        .catch(e => {
          console.warn(e);
          return "Server error";
        })
        .then(errorMessage => {
          return { id, success: false, errorMessage };
        });
    }
  });
}
