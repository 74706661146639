import { searchReducer } from "./search/search.reducer";
import { combineReducers } from "redux";
import { SearchAction } from "./search/search.actions";
import { MergeConflictsAction } from "./mergeConflicts/mergeConflicts.actions";
import { mergeConflictsReducer } from "./mergeConflicts/mergeConflicts.reducer";
import {
  changeHistoryListReducer,
  changeHistoryReducer
} from "./changeHistory/changeHistory.reducer";
import { permissionsReducer } from "./permissions/permissions.reducer";
import { noticeReducer } from "./notice/notice.reducer";
import { NoticeAction } from "./notice/notice.actions";
import { ImportVesselsAction } from "./import/import.actions";
import { importVesselsReducer } from "./import/ImportVessels.reducer";
import { administrationReducer } from "./administration/administration.reducer";

export type Action =
  | SearchAction
  | MergeConflictsAction
  | NoticeAction
  | ImportVesselsAction;

export const appReducer = combineReducers({
  search: searchReducer,
  mergeConflicts: mergeConflictsReducer,
  changeHistory: changeHistoryReducer,
  changeHistoryList: changeHistoryListReducer,
  permissions: permissionsReducer,
  notice: noticeReducer,
  importVessels: importVesselsReducer,
  administration: administrationReducer
});
