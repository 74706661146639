import { VesselUUID } from "../../domain/VesselId";
import { VesselDataChange } from "../../domain/VesselDataChange";
import { QueryString } from "../search/search.interfaces";
import { createRequest } from "../../util/Login";

export function fetchChangeHistory(
  vesselId: VesselUUID
): Promise<VesselDataChange[]> {
  return fetch(
    `/api/vessels/${encodeURIComponent(vesselId)}/history`,
    createRequest("GET")
  )
    .then(response => response.json())
    .then(x => {
      console.log(x);
      return x;
    })
    .catch(e => {
      console.warn(e);
      return [];
    });
}

export function fetchAllChangeHistory(
  offset: number,
  size: number
): Promise<VesselDataChange[]> {
  return fetch(
    `/api/vessels-history?offset=${encodeURIComponent(
      offset
    )}&size=${encodeURIComponent(size)}`,
    createRequest("GET")
  )
    .then(response => response.json())
    .then(x => {
      console.log(x);
      return x;
    })
    .catch(e => {
      console.warn(e);
      return [];
    });
}

export function search(queryString: QueryString): Promise<VesselDataChange[]> {
  return fetch(
    `/api/vessels-history/search/${encodeURIComponent(queryString)}`,
    createRequest("GET")
  )
    .then(response => response.json())
    .catch(e => {
      console.warn(e);
      return [];
    });
}
