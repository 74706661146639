import React, { Component } from "react";
import ReactTable, { Column } from "react-table";
import styles from "./MergeConflicts.module.scss";
import "react-table/react-table.css";
import { MergeConflict, MergeConflictID } from "../../domain/MergeConflict";
import { Callback, Callback0 } from "../../util/Callback";
import { AppState } from "../../store/AppState";
import { Dispatch } from "redux";
import { Action } from "../../store/reducer";
import {
  approveMergeConflict,
  loadMergeConflicts,
  rejectMergeConflict,
  setSearchTerm
} from "../../store/mergeConflicts/mergeConflicts.actions";
import { connect } from "react-redux";
import { MergeConflictView } from "./MergeConflictView";
import classNames from "classnames";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SearchBar from "./SearchBar";
import { QueryString } from "../../store/search/search.interfaces";

interface MergeConflictListProps {
  mergeConflicts: MergeConflict[];
  offset: number;
  searchTerm: string;
  setSearchTerm: Callback<QueryString>;
  searchConflicts: Callback<QueryString>;
  loadConflicts: Callback<number>;
  approve: Callback<MergeConflictID>;
  reject: Callback<MergeConflictID>;
}

export class MergeConflictList extends Component<MergeConflictListProps> {
  componentDidMount(): void {
    this.props.loadConflicts(0);
  }

  render() {
    const { mergeConflicts, searchTerm, setSearchTerm } = this.props;

    const columns: Array<Column<any>> = [
      {
        Header: "",
        width: 50,
        id: "contextMenu",
        accessor: d => (
          <ContextMenu
            approve={() => this.props.approve(d.id)}
            reject={() => this.props.reject(d.id)}
          />
        )
      },
      {
        Header: "",
        columns: [
          {
            expander: true,
            Header: () => <div />,
            width: 65,
            Expander: ({ isExpanded, ...rest }) => (
              <div>
                {isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}
              </div>
            ),
            style: {
              cursor: "pointer",
              fontSize: 25,
              padding: "0",
              textAlign: "center",
              userSelect: "none"
            }
          }
        ]
      },
      { Header: "Last Attempt", accessor: "lastUpdated" },
      { Header: "Name", accessor: "name" },
      { Header: "MMSI", accessor: "mmsi" },
      { Header: "IMO", accessor: "imo" },
      { Header: "ENI", accessor: "eni" },
      { Header: "USCG", accessor: "uscg" },
      { Header: "Sources", accessor: "sources" }
    ];

    return (
      <>
        <div className={styles.searchMenu}>
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </div>

        <ReactTable
          data={mergeConflicts}
          columns={columns}
          className={classNames("-striped", "-highlight", styles.table)}
          manual // Manual pagination
          pages={100}
          showPageSizeOptions={false}
          showPageJump={false}
          onFetchData={(state, instance) =>
            this.props.loadConflicts(state.page * state.pageSize)
          }
          SubComponent={row => (
            <MergeConflictView
              selectedMergee={row.original.mergee}
              selectedInto={row.original.into}
            />
          )}
        />
      </>
    );
  }
}

export function ContextMenu(props: { approve: Callback0; reject: Callback0 }) {
  const [isOpen, setOpen] = React.useState<boolean>(false);

  const anchorEl = React.useRef(null);

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <IconButton
        ref={anchorEl}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="source-menu"
        anchorEl={anchorEl.current}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        PaperProps={{ style: { width: 200 } }}
      >
        <MenuItem
          key="approve"
          onClick={() => {
            props.approve();
            handleClose();
          }}
        >
          Approve
        </MenuItem>
        <MenuItem
          key="reject"
          onClick={() => {
            props.reject();
            handleClose();
          }}
        >
          Reject
        </MenuItem>
      </Menu>
    </div>
  );
}

function mapStateToProps(
  state: AppState
): Pick<MergeConflictListProps, "mergeConflicts" | "offset" | "searchTerm"> {
  return {
    mergeConflicts: state.mergeConflicts.mergeConflicts,
    offset: state.mergeConflicts.offset,
    searchTerm: state.mergeConflicts.searchTerm
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<Action>
): Pick<
  MergeConflictListProps,
  "loadConflicts" | "setSearchTerm" | "approve" | "reject"
> {
  return {
    loadConflicts: (offset: number) => {
      dispatch(loadMergeConflicts(offset));
    },
    setSearchTerm: (query: QueryString) => {
      dispatch(setSearchTerm(query));
    },
    approve: (id: MergeConflictID) => {
      dispatch(approveMergeConflict(id));
    },
    reject: (id: MergeConflictID) => {
      dispatch(rejectMergeConflict(id));
    }
  };
}

export const MergeConflictListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MergeConflictList);
