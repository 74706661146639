import {SearchCriteria, UnmergeResult} from "./search.interfaces";
import { VesselDataWithSnapshot } from "../../domain/vesseldata/VesselDataWithSnapshot";
import { VesselUUID } from "../../domain/VesselId";
import { ManualVesselData } from "../../domain/vesseldata/ManualVesselData";
import { ForceMergeResult } from "../../domain/MergeSummary";
import { VesselDataSource } from "../../components/Search/search.helpers";
import { createRequest } from "../../util/Login";

export function searchVessels(searchCriteria: SearchCriteria): Promise<VesselDataWithSnapshot[]> {
  return fetch(
    `/api/search/${encodeURIComponent(
        searchCriteria.searchTerm
    )}?includeInvalid=true&searchInAllDataSources=${searchCriteria.includeAllSources}`,
    createRequest("GET")
  )
    .then(response => response.json())
    .catch(e => {
      console.warn(e);
      return [];
    });
}

export function updateVesselManualData(
  vesselId: VesselUUID,
  manualData: ManualVesselData
): Promise<VesselDataWithSnapshot> {
  const url = `/api/vessels/${vesselId}/manual`;
  const body = JSON.stringify(manualData.data);

  return fetch(url, createRequest("PUT", body))
    .then(response => response.json())
    .then(x => {
      console.log(x);
      return x;
    });
}

export function mergeVessels(
  vesselsIds: VesselUUID[]
): Promise<ForceMergeResult> {
  const body = JSON.stringify(vesselsIds);

  return fetch("/api/vessels/mergeForced", createRequest("POST", body)).then(
    response => response.json()
  );
}

export function unmergeVessel(
  vesselId: VesselUUID,
  source: VesselDataSource,
  split: boolean
): Promise<UnmergeResult> {
  let result = split
    ? fetch(`/api/vessels/${vesselId}/${source}/unmerge`, createRequest("GET"))
    : fetch(`/api/vessels/${vesselId}/${source}`, createRequest("DELETE"));

  return result.then(response => {
    if (response.status === 200) {
      return Promise.resolve({
        vesselId,
        source,
        split,
        success: true,
        errorMessage: undefined
      });
    } else {
      return response
        .json()
        .catch(e => {
          console.warn(e);
          return "Server error";
        })
        .then(errorMessage => {
          return { vesselId, source, split, success: false, errorMessage };
        });
    }
  });
}
