import { combineEpics, Epic, ofType } from "redux-observable";
import {
  incomingMergeResult,
  incomingUnmergeResult,
  incomingSearchResults,
  manualDataUpdatedResult,
  MERGE_VESSELS,
  MergeVessels,
  SearchAction,
  SET_SEARCH_CRITERIA,
  SetSearchCriteria,
  UNMERGE,
  Unmerge,
  UPDATE_MANUAL_DATA,
  UpdateManualData,
  setSearchCriteria,
  IncomingUnmergeResult,
  UNMERGE_RESULT
} from "./search.actions";
import {
  mergeVessels,
  searchVessels,
  unmergeVessel,
  updateVesselManualData
} from "./search.fetch";
import { debounce, filter, map, mergeMap } from "rxjs/operators";
import { interval } from "rxjs";
import { AppState } from "../AppState";
import { showNotice } from "../notice/notice.actions";
import { Action } from "../reducer";
import { UnmergeError } from "./search.interfaces";

const searchTermEpic: Epic<SearchAction> = action$ =>
  action$.pipe(
    ofType<SearchAction, SetSearchCriteria>(SET_SEARCH_CRITERIA),
    filter(action => action.searchCriteria.searchTerm.length >= 3),
    debounce(() => interval(300)),
    mergeMap(action => searchVessels(action.searchCriteria)),
    map(incomingSearchResults)
  );

export const updateManualDataEpic: Epic<SearchAction> = action$ =>
  action$.pipe(
    ofType<SearchAction, UpdateManualData>(UPDATE_MANUAL_DATA),
    mergeMap(action =>
      updateVesselManualData(action.vesselId, action.manualData)
    ),
    map(manualDataUpdatedResult)
  );

const mergeEpic: Epic<Action, Action, AppState> = action$ =>
  action$.pipe(
    ofType<Action, MergeVessels>(MERGE_VESSELS),
    mergeMap(action => mergeVessels(action.selectedVessels)),
    mergeMap(result => [
      incomingMergeResult(result),
      showNotice("Records merged", true)
    ])
  );

const unmergeEpic: Epic<SearchAction, SearchAction, AppState> = action$ =>
  action$.pipe(
    ofType<SearchAction, Unmerge>(UNMERGE),
    mergeMap(action =>
      unmergeVessel(action.vesselId, action.source, action.split)
    ),
    map(incomingUnmergeResult)
  );

const unmergeErrorToNotice = (e: UnmergeError) => {
  switch (e) {
    case "KeyConflict":
      return "The source could not be split off because the new record would have conflicting ship IDs (IMO or ENI) with one or more other records";
    case "ResultIsNonIdentifiableVessel":
      return "The operation could not be performed because it would result in a record without ship IDs (IMO or ENI or MMSI)";
    default:
      return e;
  }
};

const onIncomingUnmergeResultEpic: Epic<Action, Action, AppState> = (
  action$,
  state$
) =>
  action$.pipe(
    ofType<Action, IncomingUnmergeResult>(UNMERGE_RESULT),
    mergeMap((result: IncomingUnmergeResult) => {
      const message =
        (result.errorMessage &&
          unmergeErrorToNotice(result.errorMessage as UnmergeError)) ||
        (result.split ? "Source split off" : "Source removed");

      return [
        showNotice(message, !result.success),
        ...(result.success
          ? [setSearchCriteria(state$.value.search.searchCriteria)]
          : []) // Refresh search results on success
      ];
    })
  );

export const searchEpic = combineEpics(
  searchTermEpic,
  mergeEpic,
  unmergeEpic,
  onIncomingUnmergeResultEpic
);
