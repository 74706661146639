export const IsSeaVessel = "Is Sea Vessel";
export const IsStatcodeServiceVessel = "Is Statcode Service Vessel";
export const IsAisServiceVessel = "Is Ais Service Vessel";
export const VesselHasServiceVesselRole = "Vessel Has Service Vessel Role";
export const PermissionGranted = "Permission Granted";
export const PermissionRefused = "Permission Refused";
export const ManuallyCheckedNotPersonal = "Manually Checked Not Personal";
export const ManuallyCheckedPersonal = "Manually Checked Personal";

export type AisPositionAllowedReason =
  | typeof IsSeaVessel
  | typeof IsStatcodeServiceVessel
  | typeof IsAisServiceVessel
  | typeof VesselHasServiceVesselRole
  | typeof PermissionGranted
  | typeof PermissionRefused
  | typeof ManuallyCheckedNotPersonal
  | typeof ManuallyCheckedPersonal;

export const AllAisPositionAllowedReasons: AisPositionAllowedReason[] = [
  IsSeaVessel,
  IsStatcodeServiceVessel,
  IsAisServiceVessel,
  VesselHasServiceVesselRole,
  PermissionGranted,
  PermissionRefused,
  ManuallyCheckedNotPersonal,
  ManuallyCheckedPersonal
];
