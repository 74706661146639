import React, { Component } from "react";
import SearchBar from "./SearchBar";
import { SearchResults } from "./SearchResults";
import {SearchCriteria, VesselState} from "../../store/search/search.interfaces";
import { Action, Dispatch } from "redux";
import {
  modifySortOrder,
  setSearchCriteria,
  toggleVesselExpanded,
  toggleVesselSelection,
  unmerge,
  updateManualData
} from "../../store/search/search.actions";
import { Callback, Callback2, Callback3 } from "../../util/Callback";
import { connect } from "react-redux";
import { AppState } from "../../store/AppState";
import { VesselUUID } from "../../domain/VesselId";
import {
  getExpandedIds,
  getVesselStateArray
} from "../../store/search/search.selectors";
import { SortOrder } from "../../store/search/columnDefinitions";
import { ManualVesselData } from "../../domain/vesseldata/ManualVesselData";
import { SelectionOptionsContainer } from "../SelectionOptions/SelectionOptions";
import styles from "./Search.module.scss";
import { loadPermissions } from "../../store/permissions/permissions.actions";
import { VesselDataSource } from "./search.helpers";
import { recalculateVessel } from "../../store/administration/administration.actions";
import { MergeConflictID } from "../../domain/MergeConflict";
import {
  approveMergeConflict,
  rejectMergeConflict
} from "../../store/mergeConflicts/mergeConflicts.actions";

type SearchProps = Readonly<{
  searchCriteria: SearchCriteria;
  vessels: VesselState[];
  setSearchCriteria: Callback<SearchCriteria>;
  loadPermissions: () => void;
  expandedIds: Record<VesselUUID, boolean>;
  toggleVesselSelection: Callback<VesselUUID>;
  toggleVesselExpanded: Callback<VesselUUID>;
  modifySortOrder: Callback<SortOrder[]>;
  updateManualData: Callback2<VesselUUID, ManualVesselData>;
  unmerge: Callback3<VesselUUID, VesselDataSource, boolean>;
  editPermission: Boolean;
  recalculateVessel: Callback<VesselUUID>;
  approve: Callback<MergeConflictID>;
  reject: Callback<MergeConflictID>;
}>;

export class Search extends Component<SearchProps> {
  componentDidMount(): void {
    this.props.loadPermissions();
  }

  render() {
    const {
      searchCriteria,
      vessels,
      setSearchCriteria,
      toggleVesselSelection,
      toggleVesselExpanded,
      expandedIds,
      modifySortOrder,
      updateManualData,
      editPermission,
      unmerge,
      recalculateVessel,
      approve,
      reject
    } = this.props;
    return (
      <>
        <div className={styles.searchMenu}>
          <SearchBar searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
          <SelectionOptionsContainer editPermission={editPermission} />
        </div>
        <SearchResults
          vessels={vessels}
          editPermission={editPermission}
          onSelect={toggleVesselSelection}
          onExpand={toggleVesselExpanded}
          expandedIds={expandedIds}
          onModifySortOrder={modifySortOrder}
          updateManualData={updateManualData}
          unmerge={unmerge}
          recalculateVessel={recalculateVessel}
          approve={approve}
          reject={reject}
        />
      </>
    );
  }
}

function mapStateToProps(
  state: AppState
): Pick<
  SearchProps,
  "searchCriteria" | "vessels" | "expandedIds" | "editPermission"
> {
  return {
    searchCriteria: state.search.searchCriteria,
    vessels: getVesselStateArray(state.search),
    expandedIds: getExpandedIds(state.search),
    editPermission:
      state.permissions.permissions.includes("Edit") ||
      state.permissions.permissions.includes("All")
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<Action>
): Pick<
  SearchProps,
  | "setSearchCriteria"
  | "toggleVesselSelection"
  | "toggleVesselExpanded"
  | "modifySortOrder"
  | "updateManualData"
  | "loadPermissions"
  | "unmerge"
  | "recalculateVessel"
  | "approve"
  | "reject"
> {
  return {
    setSearchCriteria: searchTerm => dispatch(setSearchCriteria(searchTerm)),
    toggleVesselSelection: id => dispatch(toggleVesselSelection(id)),
    toggleVesselExpanded: id => dispatch(toggleVesselExpanded(id)),
    modifySortOrder: sortOrder => dispatch(modifySortOrder(sortOrder)),
    updateManualData: (id, manualData) =>
      dispatch(updateManualData(id, manualData)),
    loadPermissions: () => dispatch(loadPermissions()),
    unmerge: (id, source, split) => dispatch(unmerge(id, source, split)),
    recalculateVessel: (id: VesselUUID) => dispatch(recalculateVessel(id)),
    approve: (id: MergeConflictID) => dispatch(approveMergeConflict(id)),
    reject: (id: MergeConflictID) => dispatch(rejectMergeConflict(id))
  };
}

export const SearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
