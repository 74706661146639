import { combineEpics, Epic, ofType } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import {
  AdministrationAction,
  INCOMING_RECALCULATE_VESSEL,
  IncomingRecalculateVessel,
  recalculateVesselResult
} from "./administration.actions";
import { recalculateVessel } from "./administration.fetch";

const recalculateVesselEpic: Epic<AdministrationAction> = action$ =>
  action$.pipe(
    ofType<AdministrationAction, IncomingRecalculateVessel>(
      INCOMING_RECALCULATE_VESSEL
    ),
    mergeMap(action => recalculateVessel(action.id)),
    map(recalculateVesselResult)
  );

export const administrationEpic = combineEpics(recalculateVesselEpic);
