// from pronto.domain.VesselOperator.VesselOperator
export const KnownVesselOperators: string[] = [
  "Stolt",
  "Wilson",
  "Maersk",
  "Chemgas",
  "Gefogas",
  "ImperialLogistics",
  "InterstreamBarging",
  "Unitas",
  "StoltNielsenInlandTankerService",
  "HapagLloyd",
  "CmaCgm",
  "Cosco",
  "Evergreen",
  "Hmm",
  "Msc",
  "One",
  "YangMing",
  "Zim",
  "MOLNordic",
  "Kirby",
  "WalleniusWilhelmsenRoro",
  "XPress"
];
