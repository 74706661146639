export const SHOW_NOTICE = "SHOW_NOTICE";
export const HIDE_NOTICE = "HIDE_NOTICE";

export interface ShowNotice {
  type: typeof SHOW_NOTICE;
  message: string;
  error: boolean;
}

export interface HideNotice {
  type: typeof HIDE_NOTICE;
}

export function showNotice(
  message: string,
  error: boolean = false
): ShowNotice {
  return {
    type: SHOW_NOTICE,
    message,
    error
  };
}

export function hideNotice(): HideNotice {
  return { type: HIDE_NOTICE };
}

export type NoticeAction = ShowNotice | HideNotice;
