import { MergeConflictsState } from "./mergeConflicts.interfaces";
import { MergeConflictsAction } from "./mergeConflicts.actions";

export const emptyMergeConflictsState: MergeConflictsState = {
  offset: 0,
  searchTerm: "",
  mergeConflicts: []
};

export function mergeConflictsReducer(
  state: MergeConflictsState = emptyMergeConflictsState,
  action: MergeConflictsAction
): MergeConflictsState {
  switch (action.type) {
    case "LOAD_MERGE_CONFLICTS":
      return { ...state, offset: action.offset };
    case "INCOMING_MERGE_CONFLICTS":
      return { ...state, mergeConflicts: action.mergeConflicts };
    case "SET_CONFLICT_SEARCH_TERM":
      return { ...state, searchTerm: action.searchTerm };
    default:
      return state;
  }
}
