import { combineEpics, Epic } from "redux-observable";
import { searchEpic, updateManualDataEpic } from "./search/search.epics";
import { Action } from "./reducer";
import { mergeConflictsEpic } from "./mergeConflicts/mergeConflicts.epics";
import { historyEpic } from "./changeHistory/changeHistory.epics";
import { permissionsEpic } from "./permissions/permissions.epics";
import { importVesselsEpic } from "./import/import.epics";
import { administrationEpic } from "./administration/administration.epics";

//TODO how to get rid of any
export const rootEpic: Epic<Action> = combineEpics<Action, Action>(
  searchEpic as any,
  mergeConflictsEpic as any,
  historyEpic as any,
  updateManualDataEpic as any,
  permissionsEpic as any,
  importVesselsEpic as any,
  administrationEpic as any
);
