import React, { Component } from "react";
import ReactTable, { Column, RowInfo } from "react-table";
import "react-table/react-table.css";
import { SearchResultRow } from "./SearchResultRow";
import { toSearchResultSnapshot, VesselDataSource } from "./search.helpers";
import { VesselState } from "../../store/search/search.interfaces";
import classNames from "classnames";
import styles from "./Search.module.scss";

import {
  columnDefinitions,
  SortOrder
} from "../../store/search/columnDefinitions";
import selectTableHOC, {
  SelectTableAdditionalProps
} from "react-table/lib/hoc/selectTable";
import { Callback, Callback2, Callback3 } from "../../util/Callback";
import { VesselUUID } from "../../domain/VesselId";
import { ManualVesselData } from "../../domain/vesseldata/ManualVesselData";
import { MergeConflictID } from "../../domain/MergeConflict";

interface SearchResultsProps {
  vessels: VesselState[];
  expandedIds: Record<VesselUUID, boolean>;
  onSelect: Callback<VesselUUID>;
  onExpand: Callback<VesselUUID>;
  onModifySortOrder: Callback<SortOrder[]>;
  updateManualData: Callback2<VesselUUID, ManualVesselData>;
  editPermission: Boolean;
  unmerge: Callback3<VesselUUID, VesselDataSource, boolean>;
  recalculateVessel: Callback<VesselUUID>;
  approve: Callback<MergeConflictID>;
  reject: Callback<MergeConflictID>;
}

const columns: Column<VesselState>[] = [
  {
    expander: true,
    Header: () => "",
    width: 65,
    Expander: ({ isExpanded }: { isExpanded: boolean }) => (
      <div>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div>
    ),
    style: {
      cursor: "pointer",
      fontSize: 25,
      padding: "0",
      textAlign: "center",
      userSelect: "none"
    }
  },
  {
    Header: "Vessel",
    columns: [
      columnDefinitions.name,
      columnDefinitions.mmsi,
      columnDefinitions.imo,
      columnDefinitions.eni,
      columnDefinitions.uscg,
      columnDefinitions.callSign,
      columnDefinitions.shipType,
      columnDefinitions.statCode,
      columnDefinitions.maxDraught,
      columnDefinitions.lengthOverall,
      columnDefinitions.beam,
      columnDefinitions.vesselRole,
      columnDefinitions.aisPositionAllowedReason,
      columnDefinitions.comment,
      columnDefinitions.capacityContainers,
      columnDefinitions.isDeepSeaVessel,
      {
        Header: "Created",
        id: "created",
        accessor: d => d.data.metaData.createdOn
      },
      {
        Header: "Last Updated",
        id: "lastUpdated",
        accessor: d => d.data.metaData.updatedAt
      }
    ]
  }
];

const Table = selectTableHOC<ReactTable<VesselState>["props"]>(ReactTable);

function selectProps(
  onSelect: Callback<VesselUUID>,
  vessels: VesselState[]
): SelectTableAdditionalProps {
  return {
    isSelected: id => {
      const vessel = vessels.find(v => v.id === id);
      return !!vessel && vessel.selected;
    },
    selectType: "checkbox",
    keyField: "id",
    selectWidth: 65,
    toggleSelection: (key, dsa, row) => onSelect(row.id),
    SelectAllInputComponent: () => null
  };
}

export class SearchResults extends Component<SearchResultsProps> {
  render() {
    const {
      vessels,
      expandedIds,
      onSelect,
      onExpand,
      onModifySortOrder,
      updateManualData,
      editPermission,
      unmerge,
      recalculateVessel
    } = this.props;

    return (
      <div>
        <Table
          keyField="id"
          columns={columns}
          minRows={25}
          defaultPageSize={100}
          className={classNames("-striped", "-highlight", styles.table)}
          data={vessels}
          expanded={expandedIds}
          {...selectProps(onSelect, vessels)}
          onExpandedChange={(expandedIds, [rowIndex]) =>
            onExpand(vessels[rowIndex].id)
          }
          onSortedChange={newSorted =>
            onModifySortOrder(newSorted as SortOrder[])
          } //cast should be safe as long as we only have columns within the column definition
          getTrProps={(
            finalState: any,
            row?: RowInfo,
            column?: undefined,
            instance?: any
          ) =>
            row && row.original.properties.invalid
              ? { style: { textDecoration: "line-through" } }
              : {}
          }
          SubComponent={row => (
            <div>
              <SearchResultRow
                searchResultSnapshots={toSearchResultSnapshot(row.original)}
                vessel={row.original.data}
                snapshot={row.original.snapshot}
                updateManualData={updateManualData}
                editPermission={editPermission}
                unmerge={unmerge}
                recalculateVessel={recalculateVessel}
                mergeConflicts={row.original.mergeConflicts}
                approve={this.props.approve}
                reject={this.props.reject}
              />
            </div>
          )}
          getTrGroupProps={(state: any, rowInfo?: RowInfo) => {
            if (rowInfo && rowInfo.original.hasMergeConflict) {
              return {
                className: styles.mergeConflictColor
              };
            } else {
              return {};
            }
          }}
        />
      </div>
    );
  }
}
