import { AccessLevel } from "./permissions.interfaces";
export const LOAD_PERMISSIONS = "LOAD_PERMISSIONS";
export const LOAD_PERMISSIONS_RESPONSE = "LOAD_PERMISSIONS_RESPONSE";

export interface LoadPermissions {
  type: typeof LOAD_PERMISSIONS;
}

export function loadPermissions(): LoadPermissions {
  return { type: LOAD_PERMISSIONS };
}

export interface LoadPermissionsResponse {
  type: typeof LOAD_PERMISSIONS_RESPONSE;
  permissions: AccessLevel[];
}

export function loadPermissionsResponse(
  permissions: AccessLevel[]
): LoadPermissionsResponse {
  return { type: LOAD_PERMISSIONS_RESPONSE, permissions: permissions };
}

export type PermissionsAction = LoadPermissions | LoadPermissionsResponse;
