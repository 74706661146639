import { VesselState } from "./search.interfaces";
import { VesselSearchResultProperties } from "../../domain/vesseldata/VesselSearchResultProperties";

export interface SortOrder {
  id: keyof ColumnDefinitions;
  desc: boolean;
}

export interface ColumnDefinition<ID extends string> {
  id: ID;
  accessor: (data: VesselState) => any;
  Header?: string;
  width?: number;
}

type VesselSearchResultFields = Omit<
  VesselSearchResultProperties,
  "id" | "aisPositionAllowed" | "outOfService" | "invalid"
>;

/**
 * Type safe definition for all columns.
 * All fields of the VesselSnapshot except for id should be contained in the definition with at least an `id` field that
 * matches the name of the field + an accessor to obtain the field from the `VesselState`
 */
export type ColumnDefinitions = {
  [K in keyof VesselSearchResultFields]-?: ColumnDefinition<K>;
};

function booleanToString(b: boolean): string {
  return b ? "Yes" : "No";
}

export const columnDefinitions: ColumnDefinitions = {
  name: {
    id: "name",
    accessor: v => v.properties.name || "(Unknown)",
    Header: "Name",
    width: 250
  },
  mmsi: { id: "mmsi", accessor: v => v.properties.mmsi, Header: "MMSI" },
  imo: { id: "imo", accessor: v => v.properties.imo, Header: "IMO" },
  eni: { id: "eni", accessor: v => v.properties.eni, Header: "ENI" },
  uscg: { id: "uscg", accessor: v => v.properties.uscg, Header: "USCG" },
  callSign: {
    id: "callSign",
    accessor: v => v.properties.callSign,
    Header: "Callsign"
  },
  shipType: {
    id: "shipType",
    accessor: v => v.properties.shipType,
    Header: "Ship type"
  },
  statCode: {
    id: "statCode",
    accessor: v => v.properties.statCode,
    Header: "Stat code"
  },
  maxDraught: {
    id: "maxDraught",
    accessor: v => v.properties.maxDraught,
    Header: "Max draught"
  },
  lengthOverall: {
    id: "lengthOverall",
    accessor: v => v.properties.lengthOverall,
    Header: "Length"
  },
  beam: {
    id: "beam",
    accessor: v => v.properties.beam,
    Header: "Beam"
  },
  aisPositionAllowedReason: {
    id: "aisPositionAllowedReason",
    accessor: v =>
      v.properties.aisPositionAllowedReason ||
      booleanToString(v.properties.aisPositionAllowed),
    Header: "AIS Position allowed"
  },
  comment: {
    id: "comment",
    accessor: v => v.properties.comment,
    Header: "Comments"
  },
  vesselRole: {
    id: "vesselRole",
    Header: "Role",
    accessor: v => v.properties.vesselRole
  },
  capacityContainers: {
    id: "capacityContainers",
    Header: "Capacity (TEU)",
    accessor: v => v.properties.capacityContainers
  },
  isDeepSeaVessel: {
    id: "isDeepSeaVessel",
    Header: "Is Deep Sea Vessel",
    accessor: v => {
      if (v.properties.isDeepSeaVessel !== undefined) {
        return booleanToString(v.properties.isDeepSeaVessel);
      } else return "";
    }
  }
};
