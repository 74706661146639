import { VesselDataWithSnapshot } from "../../domain/vesseldata/VesselDataWithSnapshot";
import { VesselUUID } from "../../domain/VesselId";
import { SortOrder } from "./columnDefinitions";
import { ForceMergeResult } from "../../domain/MergeSummary";
import { ManualVesselData } from "../../domain/vesseldata/ManualVesselData";
import { VesselDataSource } from "../../components/Search/search.helpers";
import {SearchCriteria, UnmergeResult} from "./search.interfaces";

export const SET_SEARCH_CRITERIA = "SET_SEARCH_CRITERIA";
export const INCOMING_SEARCH_RESULTS = "INCOMING_SEARCH_RESULTS";
export const TOGGLE_VESSEL_SELECTION = "TOGGLE_VESSEL_SELECTION";

export interface SetSearchCriteria {
  type: typeof SET_SEARCH_CRITERIA;
  searchCriteria: SearchCriteria
}

export function setSearchCriteria(searchCriteria: SearchCriteria): SetSearchCriteria {
  return { type: SET_SEARCH_CRITERIA, searchCriteria };
}

export interface IncomingSearchResults {
  type: typeof INCOMING_SEARCH_RESULTS;
  searchResults: VesselDataWithSnapshot[];
}

export function incomingSearchResults(
  searchResults: VesselDataWithSnapshot[]
): IncomingSearchResults {
  return { type: INCOMING_SEARCH_RESULTS, searchResults: searchResults };
}

export interface ToggleVesselSelection {
  type: typeof TOGGLE_VESSEL_SELECTION;
  vesselId: VesselUUID;
}

export function toggleVesselSelection(
  vesselId: VesselUUID
): ToggleVesselSelection {
  return {
    type: TOGGLE_VESSEL_SELECTION,
    vesselId
  };
}

export const TOGGLE_VESSEL_EXPANDED = "TOGGLE_VESSEL_EXPANDED";

export interface ToggleVesselExpanded {
  type: typeof TOGGLE_VESSEL_EXPANDED;
  vesselId: VesselUUID;
}

export function toggleVesselExpanded(
  vesselId: VesselUUID
): ToggleVesselExpanded {
  return {
    type: TOGGLE_VESSEL_EXPANDED,
    vesselId
  };
}

export const MODIFY_SORT_ORDER = "MODIFY_SORT_ORDER";

export interface ModifySortOrder {
  type: typeof MODIFY_SORT_ORDER;
  sortOrder: SortOrder[];
}

export function modifySortOrder(sortOrder: SortOrder[]): ModifySortOrder {
  return {
    type: MODIFY_SORT_ORDER,
    sortOrder
  };
}

export const UPDATE_MANUAL_DATA = "UPDATE_MANUAL_DATA";
export const UPDATE_MANUAL_DATA_RESULT = "UPDATE_MANUAL_DATA_RESULT";

export interface UpdateManualData {
  type: typeof UPDATE_MANUAL_DATA;
  vesselId: VesselUUID;
  manualData: ManualVesselData;
}

export function updateManualData(
  vesselId: VesselUUID,
  manualData: ManualVesselData
): UpdateManualData {
  return { type: UPDATE_MANUAL_DATA, vesselId, manualData };
}

export interface ManualDataUpdatedResult {
  type: typeof UPDATE_MANUAL_DATA_RESULT;
  updatedVessel: VesselDataWithSnapshot;
}

export function manualDataUpdatedResult(
  updatedVessel: VesselDataWithSnapshot
): ManualDataUpdatedResult {
  return { type: UPDATE_MANUAL_DATA_RESULT, updatedVessel: updatedVessel };
}

export const UNMERGE = "UNMERGE";

export interface Unmerge {
  type: typeof UNMERGE;
  vesselId: VesselUUID;
  source: VesselDataSource;
  split: boolean;
}

export function unmerge(
  vesselId: VesselUUID,
  source: VesselDataSource,
  split: boolean
): Unmerge {
  return { type: UNMERGE, vesselId, source, split };
}

export const UNMERGE_RESULT = "UNMERGE_RESULT";

export interface IncomingUnmergeResult {
  type: typeof UNMERGE_RESULT;
  vesselId: VesselUUID;
  source: VesselDataSource;
  split: boolean;
  success: boolean;
  errorMessage?: string;
}

export function incomingUnmergeResult({
  vesselId,
  source,
  split,
  success,
  errorMessage
}: UnmergeResult): IncomingUnmergeResult {
  return {
    type: UNMERGE_RESULT,
    vesselId,
    source,
    split,
    success,
    errorMessage
  };
}

export const MERGE_VESSELS = "MERGE_VESSELS";

export interface MergeVessels {
  type: typeof MERGE_VESSELS;
  selectedVessels: VesselUUID[];
}

export function mergeVessels(selectedVessels: VesselUUID[]): MergeVessels {
  return {
    type: MERGE_VESSELS,
    selectedVessels
  };
}

export const INCOMING_MERGE_RESULT = "INCOMING_MERGE_RESULT";

export interface IncomingMergeResult {
  type: typeof INCOMING_MERGE_RESULT;
  forceMergeResult: ForceMergeResult;
}

export function incomingMergeResult(
  forceMergeResult: ForceMergeResult
): IncomingMergeResult {
  return {
    type: INCOMING_MERGE_RESULT,
    forceMergeResult
  };
}
export type SearchAction =
  | SetSearchCriteria
  | IncomingSearchResults
  | ToggleVesselSelection
  | ToggleVesselExpanded
  | UpdateManualData
  | ManualDataUpdatedResult
  | ModifySortOrder
  | MergeVessels
  | IncomingMergeResult
  | Unmerge
  | IncomingUnmergeResult;
