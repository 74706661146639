import {VesselState} from "../../store/search/search.interfaces";
import {VesselUUID} from "../../domain/VesselId";
import {VesselSearchResultProperties} from "../../domain/vesseldata/VesselSearchResultProperties";

export const Hamis = "Hamis";
export const Ais = "Ais";
export const Uscg = "Uscg";
export const Manual = "Manual";
export const AisPrivacyPermission = "AisPrivacyPermission";
export const Carrier = "Carrier";
export const Spire = "SpireV2";

export type VesselDataSource =
    | typeof Hamis
    | typeof Ais
    | typeof Uscg
    | typeof Manual
    | typeof AisPrivacyPermission
    | typeof Carrier
    | typeof Spire;

export type SearchResultSnapshots = [
    SearchResultSnapshot<typeof Hamis>?,
    SearchResultSnapshot<typeof Ais>?,
    SearchResultSnapshot<typeof Uscg>?,
    SearchResultSnapshot<typeof AisPrivacyPermission>?,
    SearchResultSnapshot<typeof Manual>?,
    SearchResultSnapshot<typeof Carrier>?,
    SearchResultSnapshot<typeof Spire>?
];

export interface SearchResultSnapshot<Source extends VesselDataSource>
    extends Partial<VesselSearchResultProperties> {
    id: VesselUUID;
    dataSource: Source;
}

export function toSearchResultSnapshot(
    vesselData: VesselState
): SearchResultSnapshots {
    return [
        vesselData.propertiesBySource.hamis
            ? {
                ...vesselData.propertiesBySource.hamis,
                id: vesselData.id,
                dataSource: Hamis
            }
            : undefined,
        vesselData.propertiesBySource.ais
            ? {
                ...vesselData.propertiesBySource.ais,
                id: vesselData.id,
                dataSource: Ais
            }
            : undefined,
        vesselData.propertiesBySource.uscg
            ? {
                ...vesselData.propertiesBySource.uscg,
                id: vesselData.id,
                dataSource: Uscg
            }
            : undefined,
        vesselData.propertiesBySource.aisPrivacyPermission
            ? {
                ...vesselData.propertiesBySource.aisPrivacyPermission,
                id: vesselData.id,
                dataSource: AisPrivacyPermission
            }
            : undefined,
        vesselData.propertiesBySource.manual
            ? {
                ...vesselData.propertiesBySource.manual,
                id: vesselData.id,
                dataSource: Manual
            }
            : undefined,
        vesselData.propertiesBySource.carrier
            ? {
                ...vesselData.propertiesBySource.carrier,
                id: vesselData.id,
                dataSource: Carrier
            }
            : undefined,
        vesselData.propertiesBySource.spire
            ? {
                ...vesselData.propertiesBySource.spire,
                id: vesselData.id,
                dataSource: Spire
            }
            : undefined
    ];
}
