import React, { Component } from "react";
import {Button, Col, Form, FormGroup, FormText, Input, Label, Spinner, Table} from 'reactstrap'
import styles from "./ImportVessels.module.scss";
import {KnownVesselOperators} from '../../domain/VesselOperator'
import {Callback} from '../../util/Callback'
import { connect } from "react-redux";
import {AppState} from '../../store/AppState'
import {Dispatch} from 'redux'
import {Action} from '../../store/reducer'
import {ImportVesselsData, ImportVesselsImportResult, ImportRow} from '../../store/import/import.interfaces'
import {sendVesselsForImport} from '../../store/import/import.actions'
import {ColumnIndices, csvToImportRow, parseCSV, ParsedCSV} from './CSVParser'

type ImportVesselsProps = Readonly<{
  pendingImport: boolean
  importResult ?: ImportVesselsImportResult

  sendDataForImport: Callback<ImportVesselsData>
}>;

interface ImportVesselsLocalState {
  pendingImport: boolean

  indices: ColumnIndices
  csv: ParsedCSV
  rows: ImportRow[]
  carrier: string
}

/**
 * A component that allows a user to select a carrier and paste some CSV/TSV
 * The frontend will then
 * - parse this into columns
 * - Try to match columns to fields (e.g. "Vessel name" column to Name field)
 *
 * And allow the user to
 * - Switch which columns are matched to which field
 * - Submit the rows linked to fields to the backend
 * - View the result of the import
 */
export class ImportVessels extends Component<ImportVesselsProps, ImportVesselsLocalState> {
  constructor(props: ImportVesselsProps) {
    super(props);
    this.state = {
      pendingImport: false,
      indices: {},
      csv: {indices: {}, header: [], rows: []},
      rows: [],
      carrier: KnownVesselOperators[0]
    };
  }

  onSubmit = () => {
    this.props.sendDataForImport({
      carrier: this.state.carrier,
      rows: this.state.rows
    })
  };

  setOperator:  Callback<React.ChangeEvent<HTMLInputElement>> = e => {
    this.setState({
      ...this.state,
      carrier: e.target.value
    })
  }

  setVesselInputData: Callback<React.ChangeEvent<HTMLInputElement>> = e => {
    let csv = parseCSV(e.target.value)

    // If the automatic matched indices changed, overwrite the user indices, otherwise keep them
    let indices = this.state.csv.indices !== csv.indices ? csv.indices : this.state.indices

    this.setState({
      ...this.state,
      csv: csv,
      indices: indices,
      rows: csvToImportRow({...this.state.csv, indices: indices})
    })
  }

  setIndices: (col: keyof ColumnIndices) => Callback<React.ChangeEvent<HTMLInputElement>> = col => e => {
    let column = e.target.value
    let newIndices = {...this.state.indices}
    newIndices[col] = column === "-1" ? undefined : parseInt(column)
    this.setState({
      ...this.state,
      indices: newIndices,
      rows: csvToImportRow({...this.state.csv, indices: newIndices})
    })
  }


  render() {
    return (
      <div className={styles.importVessels}>
        <h1>Bulk import vessels</h1>
        <Form>

          <FormGroup row>
            <Col sm={1}><Label for="carrier">Carrier</Label></Col>
            <Col sm={60}>
              <Input type="select" name="carrier" id="carrier" value={this.state.carrier} onChange={this.setOperator}>
                {KnownVesselOperators.map(operator => <option key={operator}>{operator}</option>)}
              </Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={1}><Label for="input">Input data</Label></Col>
            <Col>
              <FormText>Comma- or tab-separated columns with header, for example an Excel-copy</FormText>
              <Input
                type="textarea"
                name="vesseldata"
                id="vesseldata"
                onChange={this.setVesselInputData}
                placeholder={"IMO,MMSI,Name\n9876543,123456789,My ship"}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={1}><Label>Columns</Label></Col>
            <Col>
              <FormGroup row>
                <Col sm={1}><Label>IMO</Label></Col><Col>{this.columnSelector("imo")}</Col>
                <Col sm={1}><Label>ENI</Label></Col><Col>{this.columnSelector("eni")}</Col>
                <Col sm={1}><Label>MMSI</Label></Col><Col>{this.columnSelector("mmsi")}</Col>
                <Col sm={1}><Label>Name</Label></Col><Col>{this.columnSelector("name")}</Col>
                <Col sm={1}><Label>Call Sign</Label></Col><Col>{this.columnSelector("callSign")}</Col>
              </FormGroup>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={1}><Label for="output">Tabular input</Label></Col>
            <Col>
              <div className={styles.outputTable}>
                <Table striped>
                  <thead><tr><th>Line</th><th>Name</th><th>IMO</th><th>ENI</th><th>MMSI</th><th>Call Sign</th></tr></thead>
                  <tbody>
                  {this.state.rows.map((row, i) =>
                    <tr key={i}><td>{i}</td><td>{row.name}</td><td>{row.imo}</td><td>{row.eni}</td><td>{row.mmsi}</td><td>{row.callSign}</td></tr>
                  )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={1}><Label for="submit">Submit</Label></Col>
            <Col sm={4}>
              {this.props.pendingImport ?
                <Spinner /> :
                <Button disabled={this.state.rows.length === 0} onClick={this.onSubmit}>Import {this.state.rows.length} vessels</Button>
              }
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={1}>Imported</Col>
            <Col sm={4}>
              {this.outputTable()}
            </Col>
          </FormGroup>
        </Form>
      </div>
    )
  }

  columnSelector(col: keyof ColumnIndices) {
    return (
      <Input type="select" name={col} value={this.state.indices[col] ?? -1} onChange={this.setIndices(col)} className="col-sm-8">
        <option value="-1">None</option>
        {this.state.csv.header.map((value, idx) =>
          <option key={idx} value={idx}>{value}</option>
        )}
      </Input>
    )
  }

  outputTable() {
    if(this.props.pendingImport) {
      return <Spinner />
    }
    let result = this.props.importResult
    switch(result?.status) {
      case undefined:
        return (<></>)
      case "failure":
        return  (<>
          <p><strong>Error on import:</strong></p>
          <code>{result.response.error}</code>
          {result.response.invalidRows ?
            <Table striped>
              <thead><tr><th>Line</th><th>Error</th></tr></thead>
              <tbody>
              {result.response.invalidRows.map(row => {
                const [i, err] = row
                return <tr key={i}><td>{i}</td><td>{err}</td></tr>
              })}
              </tbody>
            </Table>
          : <></>}
        </>)
      case "processed":
        return (
            <Table striped>
              <thead><tr><th>Name</th><th>IMO</th><th>ENI</th><th>MMSI</th><th>Call Sign</th></tr></thead>
              <tbody>
              {result.response.map((row, i) =>
                <tr key={i}><td>{row.name}</td><td>{row.imo}</td><td>{row.eni}</td><td>{row.mmsi}</td><td>{row.callSign}</td></tr>
              )}
              </tbody>
            </Table>
        )
    }
    return (<></>)
  }
}

export const ImportVesselsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportVessels);

function mapStateToProps(state: AppState):
  Pick<ImportVesselsProps, "importResult"> &
  Pick<ImportVesselsProps, "pendingImport"> {
  return {
    pendingImport: state.importVessels.pendingImport,
    importResult: state.importVessels.importResult
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>): Pick<ImportVesselsProps, "sendDataForImport"> {
  return {
    sendDataForImport: (data: ImportVesselsData) => {
      dispatch(sendVesselsForImport(data));
    }
  };
}
