export const PAGES = {
  login: "/login",
  logout: "/logout",
  explorer: "/",
  search: "/search",
  mergeConflicts: "/mergeConflicts",
  history: "/history/:id",
  import: "/import",
  callback: "/callback"
};
