import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import ReactJson from "react-json-view";
import { VesselSnapshot } from "../../domain/vesseldata/VesselSnapshot";
import { Callback } from "../../util/Callback";
import { VesselUUID } from "../../domain/VesselId";

type ViewSnapshotModalProps = Readonly<{
  id: VesselUUID;
  snapshot?: VesselSnapshot;
  onClose: () => void;
  recalculateVessel: Callback<VesselUUID>;
}>;

export class ViewSnapshotModal extends Component<ViewSnapshotModalProps> {
  render() {
    return (
      <div>
        <Modal isOpen={!!this.props.snapshot} size="lg">
          <ModalHeader toggle={this.props.onClose}>Public Snapshot</ModalHeader>
          <ModalBody>
            {this.props.snapshot && (
              <ReactJson
                src={this.props.snapshot}
                enableClipboard={false}
                displayDataTypes={false}
                sortKeys={true}
                displayObjectSize={false}
              />
            )}{" "}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.props.recalculateVessel(this.props.id);
                this.props.onClose();
              }}
            >
              Recalculate vessel snapshot
            </Button>{" "}
            <Button color="secondary" onClick={() => this.props.onClose()}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
