import { createSelector } from "reselect";
import { SearchState, VesselState } from "./search.interfaces";
import { columnDefinitions, SortOrder } from "./columnDefinitions";
import { Map } from "immutable";

import defaultProps from "react-table/lib/defaultProps";

export const getSearchResults = (s: SearchState) => s.searchResults;
export const getSearchOrder = (s: SearchState) => s.sortOrder;

export const getVesselStateArray = createSelector(
  [getSearchResults, getSearchOrder],
  (searchResults, sortOrder) => {
    const result = searchResults
      .valueSeq()
      .sortBy<[VesselState, number]>((v, k) => [v, k], multiSort(sortOrder))
      .toArray();

    return result;
  }
);

function multiSort(sortOrder: SortOrder[]) {
  return (
    [a, aIndex]: [VesselState, number],
    [b, bIndex]: [VesselState, number]
  ) => {
    for (const { id, desc } of sortOrder) {
      const accessor = columnDefinitions[id].accessor;
      const order = sortMethod(accessor(a), accessor(b), desc);
      if (order !== 0) {
        return order;
      }
    }
    return sortMethod(aIndex, bIndex, false);
  };
}

export const sortMethod = (a: any, b: any, desc: boolean) => {
  //The default sort method ignores sorting order, so we do it ourselves
  const result = defaultProps.defaultSortMethod(a, b, desc);
  return desc && result !== 0 ? -result : result;
};

export const getExpandedIds = createSelector(
  getVesselStateArray,
  s =>
    Map(
      s
        .map<[VesselState, number]>((v, i) => [v, i])
        .filter(([v, i]) => v.expanded)
        .map(([v, i]) => [i, true])
    ).toObject()
);

export const makeGetSelectedIds = () =>
  createSelector(
    getVesselStateArray,
    s => s.filter(v => v.selected).map(v => v.id)
  );

export const getSelectedIds = makeGetSelectedIds();

export const hasSelectedVessels = createSelector(
  getSelectedIds,
  ids => ids.length > 0
);
