import {
  ChangeHistoryAction,
  INCOMING_CHANGE_HISTORY,
  INCOMING_CHANGE_HISTORY_LIST,
  INCOMING_CHANGE_HISTORY_SEARCH_RESULT,
  LOAD_CHANGE_HISTORY,
  LOAD_CHANGE_HISTORY_LIST,
  SET_CHANG_HISTORY_SEARCH_TERM,
  VIEW_CHANGE_HISTORY
} from "./changeHistory.actions";
import {
  ChangeHistoryListState,
  ChangeHistoryState
} from "./changeHistory.interfaces";

export const emptyChangeHistoryState: ChangeHistoryState = {
  vesselId: undefined,
  history: [],
  showItem: undefined
};

export const emptyChangeHistoryListState: ChangeHistoryListState = {
  history: [],
  searchTerm: ""
};

export function changeHistoryReducer(
  state: ChangeHistoryState = emptyChangeHistoryState,
  action: ChangeHistoryAction
): ChangeHistoryState {
  switch (action.type) {
    case LOAD_CHANGE_HISTORY:
      return { ...state, vesselId: action.vesselId };
    case INCOMING_CHANGE_HISTORY:
      return { ...state, history: action.history };
    case VIEW_CHANGE_HISTORY:
      return { ...state, showItem: action.item };
    default:
      return state;
  }
}

export function changeHistoryListReducer(
  state: ChangeHistoryListState = emptyChangeHistoryListState,
  action: ChangeHistoryAction
): ChangeHistoryListState {
  switch (action.type) {
    case LOAD_CHANGE_HISTORY_LIST:
      return state;
    case INCOMING_CHANGE_HISTORY_LIST:
      return { ...state, history: action.history };
    case SET_CHANG_HISTORY_SEARCH_TERM:
      return { ...state, searchTerm: action.searchTerm };
    case INCOMING_CHANGE_HISTORY_SEARCH_RESULT:
      return { ...state, history: action.history };
    default:
      return state;
  }
}
