import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ReactJson from "react-json-view";
import { VesselDataChange } from "../../domain/VesselDataChange";
import styles from "./ChangeHistory.module.scss";

type ChangeHistoryItemProps = Readonly<{
  history?: VesselDataChange;
  onClose: () => void;
}>;

const modalXXL: React.CSSProperties = {
    maxWidth: '1200px'
}

function SimpleJsonView(props: { src: any }) {
  return (
    <ReactJson
      src={props.src}
      enableClipboard={false}
      displayDataTypes={false}
      sortKeys={true}
      displayObjectSize={false}
    />
  );
}

export function ChangeHistoryItemModal(props: ChangeHistoryItemProps) {
  return (
    <Modal isOpen={!!props.history} size="xl" style={modalXXL}>
      <ModalHeader toggle={props.onClose}>History</ModalHeader>
      <ModalBody>
        {props.history && (
          <div className="container">
            <div className="row">
              <div className="col-sm">Original</div>
              <div className="col-sm">New data</div>
              <div className="col-sm">Result</div>
            </div>
            <div className="row">
              <div className={styles.historyJson + " col-sm"}>
                <SimpleJsonView src={props.history.into} />
              </div>
              <div className={styles.historyJson + " col-sm"}>
                <SimpleJsonView src={props.history.mergee} />
              </div>
              <div className={styles.historyJson + " col-sm"}>
                <SimpleJsonView src={props.history.result} />
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}
