// These get defined by external javascript that is generated at runtime by the backend,
// which has availability over these env vars
declare var REACT_APP_AUTH0_CLIENT_ID: string;
declare var REACT_APP_AUTH0_AUDIENCE: string;
declare var REACT_APP_AUTH0_DOMAIN: string;
declare var REACT_APP_COGNITO_CLIENT_ID: string;
declare var REACT_APP_COGNITO_REGION: string;

export const AUTH0_CLIENT_ID = REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN = REACT_APP_AUTH0_DOMAIN;
export const AUTH0_AUDIENCE = REACT_APP_AUTH0_AUDIENCE;
export const COGNITO_REGION = REACT_APP_COGNITO_REGION;
export const COGNITO_CLIENT_ID = REACT_APP_COGNITO_CLIENT_ID;
