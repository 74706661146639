import { PermissionsState } from "./permissions.interfaces";
import {
  LOAD_PERMISSIONS_RESPONSE,
  PermissionsAction
} from "./permissions.actions";

export const emptyPermissionsState: PermissionsState = {
  permissions: []
};

export function permissionsReducer(
  state: PermissionsState = emptyPermissionsState,
  action: PermissionsAction
): PermissionsState {
  switch (action.type) {
    case LOAD_PERMISSIONS_RESPONSE:
      return {
        ...state,
        permissions: action.permissions
      };
    default:
      return state;
  }
}
