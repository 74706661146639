import * as React from "react";
import { Redirect } from "react-router";
import LoginPage from "./LoginPage";
import { PAGES } from "../../constants/pages";
import { tokenStorage } from "./TokenStorage";

export class LoginContainer extends React.Component {
  public render() {
    if (tokenStorage.isAuthenticated()) {
      return <Redirect to={PAGES.explorer} />;
    }

    return <LoginPage />;
  }
}
