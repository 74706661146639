import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { MergeConflict, MergeConflictID } from "../../domain/MergeConflict";
import classNames from "classnames";
import styles from "../MergeConflicts/MergeConflicts.module.scss";
import { MergeConflictView } from "../MergeConflicts/MergeConflictView";
import ReactTable, { Column } from "react-table";
import { Callback } from "../../util/Callback";
import { ContextMenu } from "../MergeConflicts/MergeConflictList";

type MergeConflictsModalProps = Readonly<{
  mergeConflicts: MergeConflict[];
  approve: Callback<MergeConflictID>;
  reject: Callback<MergeConflictID>;
  onClose: () => void;
}>;

interface MergeConflictsModalState {
  mergeConflicts: MergeConflict[];
}

export class MergeConflictsModal extends Component<
  MergeConflictsModalProps,
  MergeConflictsModalState
> {
  constructor(props: MergeConflictsModalProps) {
    super(props);
    this.state = {
      mergeConflicts: this.props.mergeConflicts
    };
  }

  removeFromState(id: MergeConflictID) {
    this.setState({
      mergeConflicts: this.state.mergeConflicts.filter(m => m.id !== id)
    });
  }

  render() {
    const MergeConflictColumns: Array<Column<any>> = [
      {
        Header: "",
        width: 50,
        id: "contextMenu",
        accessor: d => (
          <ContextMenu
            approve={() => {
              this.removeFromState(d.id);
              this.props.approve(d.id);
            }}
            reject={() => {
              this.removeFromState(d.id);
              this.props.reject(d.id);
            }}
          />
        )
      },
      {
        Header: "",
        columns: [
          {
            expander: true,
            Header: () => <div />,
            width: 65,
            Expander: ({ isExpanded, ...rest }) => (
              <div>
                {isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}
              </div>
            ),
            style: {
              cursor: "pointer",
              fontSize: 25,
              padding: "0",
              textAlign: "center",
              userSelect: "none"
            }
          }
        ]
      },
      { Header: "Last Attempt", accessor: "lastUpdated" },
      { Header: "Sources", accessor: "sources" }
    ];

    return (
      <div>
        <Modal isOpen={!!this.props.mergeConflicts} size="xl">
          <ModalHeader toggle={this.props.onClose}>Merge conflicts</ModalHeader>
          <ModalBody>
            <ReactTable
              data={this.state.mergeConflicts}
              columns={MergeConflictColumns}
              className={classNames("-striped", "-highlight", styles.table)}
              showPageSizeOptions={false}
              showPageJump={false}
              SubComponent={row => (
                <MergeConflictView
                  selectedMergee={row.original.mergee}
                  selectedInto={row.original.into}
                />
              )}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
