import styles from "./SelectionOptions.module.scss";
import { Button } from "reactstrap";
import * as React from "react";
import { AppState } from "../../store/AppState";
import { getSelectedIds } from "../../store/search/search.selectors";
import { Dispatch } from "redux";
import { Action } from "../../store/reducer";
import { VesselUUID } from "../../domain/VesselId";
import { mergeVessels } from "../../store/search/search.actions";
import { Callback } from "../../util/Callback";
import { connect } from "react-redux";

interface SelectionOptionsProps {
  selectedVessels: VesselUUID[];
  editPermission: Boolean;
  onMerge: Callback<VesselUUID[]>;
}

export const SelectionOptions: React.FC<SelectionOptionsProps> = ({
  selectedVessels,
  onMerge,
  editPermission
}) => (
  <div className={styles.selectionOptions}>
    <Button
      disabled={selectedVessels.length < 2 || !editPermission}
      size="sm"
      onClick={() => onMerge(selectedVessels)}
    >
      Merge
    </Button>
  </div>
);

type StateProps = Pick<SelectionOptionsProps, "selectedVessels">;

function mapStateToProps(state: AppState): StateProps {
  return {
    selectedVessels: getSelectedIds(state.search)
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<Action>
): Pick<SelectionOptionsProps, "onMerge"> {
  return {
    onMerge: (vesselIds: VesselUUID[]) => dispatch(mergeVessels(vesselIds))
  };
}

export const SelectionOptionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionOptions);
