import { VesselDataChange } from "../../domain/VesselDataChange";
import { VesselUUID } from "../../domain/VesselId";

export const LOAD_CHANGE_HISTORY = "LOAD_CHANGE_HISTORY";
export const LOAD_CHANGE_HISTORY_LIST = "LOAD_CHANGE_HISTORY_LIST";
export const INCOMING_CHANGE_HISTORY = "INCOMING_CHANGE_HISTORY";
export const INCOMING_CHANGE_HISTORY_LIST = "INCOMING_CHANGE_HISTORY_LIST";
export const VIEW_CHANGE_HISTORY = "VIEW_CHANGE_HISTORY";
export const INCOMING_CHANGE_HISTORY_SEARCH_RESULT =
  "INCOMING_CHANGE_HISTORY_SEARCH_RESULT";

export const SET_CHANG_HISTORY_SEARCH_TERM = "SET_CHANG_HISTORY_SEARCH_TERM";

export interface LoadChangeHistory {
  type: typeof LOAD_CHANGE_HISTORY;
  vesselId: VesselUUID;
}

export function loadChangeHistory(id: VesselUUID): LoadChangeHistory {
  return { type: LOAD_CHANGE_HISTORY, vesselId: id };
}

export interface LoadChangeHistoryList {
  type: typeof LOAD_CHANGE_HISTORY_LIST;
  offset: number;
  size: number;
}

export function loadChangeHistoryList(
  offset: number,
  size: number
): LoadChangeHistoryList {
  return { type: LOAD_CHANGE_HISTORY_LIST, offset, size };
}

export interface IncomingChangeHistory {
  type: typeof INCOMING_CHANGE_HISTORY;
  history: VesselDataChange[];
}

export function incomingChangeHistory(
  changeHistory: VesselDataChange[]
): IncomingChangeHistory {
  return { type: INCOMING_CHANGE_HISTORY, history: changeHistory };
}

export interface IncomingChangeHistoryList {
  type: typeof INCOMING_CHANGE_HISTORY_LIST;
  history: VesselDataChange[];
}

export function incomingChangeHistoryList(
  changeHistory: VesselDataChange[]
): IncomingChangeHistoryList {
  return { type: INCOMING_CHANGE_HISTORY_LIST, history: changeHistory };
}

export interface ViewChangeHistory {
  type: typeof VIEW_CHANGE_HISTORY;
  item?: VesselDataChange;
}

export function viewChangeHistory(item?: VesselDataChange): ViewChangeHistory {
  return { type: VIEW_CHANGE_HISTORY, item: item };
}

export interface SetSearchTerm {
  type: typeof SET_CHANG_HISTORY_SEARCH_TERM;
  searchTerm: string;
}

export function setSearchTerm(searchTerm: string): SetSearchTerm {
  return { type: SET_CHANG_HISTORY_SEARCH_TERM, searchTerm };
}

export interface IncomingChangeHistorySearchResult {
  type: typeof INCOMING_CHANGE_HISTORY_SEARCH_RESULT;
  history: VesselDataChange[];
}

export function incomingChangeHistorySearchResult(
  changeHistory: VesselDataChange[]
): IncomingChangeHistorySearchResult {
  return {
    type: INCOMING_CHANGE_HISTORY_SEARCH_RESULT,
    history: changeHistory
  };
}

export type ChangeHistoryAction =
  | LoadChangeHistory
  | LoadChangeHistoryList
  | IncomingChangeHistory
  | IncomingChangeHistoryList
  | ViewChangeHistory
  | SetSearchTerm
  | IncomingChangeHistorySearchResult;
