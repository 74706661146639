import { combineEpics, Epic, ofType } from "redux-observable";
import { debounce, filter, map, mergeMap } from "rxjs/operators";
import { interval } from "rxjs";
import {
  APPROVE_MERGE_CONFLICT,
  ApproveMergeConflict,
  INCOMING_APPROVE_MERGE_CONFLICT_RESULT,
  INCOMING_REJECT_MERGE_CONFLICT_RESULT,
  IncomingApproveMergeConflictResult,
  incomingApproveMergeConflictResult,
  incomingMergeConflicts,
  IncomingRejectMergeConflictResult,
  incomingRejectMergeConflictResult,
  LOAD_MERGE_CONFLICTS,
  loadMergeConflicts,
  LoadMergeConflicts,
  MergeConflictsAction,
  REJECT_MERGE_CONFLICT,
  RejectMergeConflict,
  SET_CONFLICT_SEARCH_TERM,
  SetSearchTerm
} from "./mergeConflicts.actions";
import {
  approveMergeConflict,
  rejectMergeConflict,
  searchMergeConflicts,
  searchMergeConflictsByQuery
} from "./mergeConflicts.fetch";
import { AppState } from "../AppState";
import { Action } from "../reducer";
import { showNotice } from "../notice/notice.actions";
import {
  MergeConflictApproveError,
  MergeConflictRejectError
} from "./mergeConflicts.interfaces";

const loadMergeConflictsEpic: Epic<MergeConflictsAction> = action$ =>
  action$.pipe(
    ofType<MergeConflictsAction, LoadMergeConflicts>(LOAD_MERGE_CONFLICTS),
    debounce(() => interval(300)),
    mergeMap(action => searchMergeConflicts(action.offset)),
    map(incomingMergeConflicts)
  );

const searchMergeConflictsEpic: Epic<MergeConflictsAction> = action$ =>
  action$.pipe(
    ofType<MergeConflictsAction, SetSearchTerm>(SET_CONFLICT_SEARCH_TERM),
    filter(action => action.searchTerm.length >= 3),
    debounce(() => interval(300)),
    mergeMap(action => searchMergeConflictsByQuery(action.searchTerm)),
    map(incomingMergeConflicts)
  );

const approveMergeConflictEpic: Epic<MergeConflictsAction> = action$ =>
  action$.pipe(
    ofType<MergeConflictsAction, ApproveMergeConflict>(APPROVE_MERGE_CONFLICT),
    mergeMap(action => approveMergeConflict(action.id)),
    map(incomingApproveMergeConflictResult)
  );

const rejectMergeConflictEpic: Epic<MergeConflictsAction> = action$ =>
  action$.pipe(
    ofType<MergeConflictsAction, RejectMergeConflict>(REJECT_MERGE_CONFLICT),
    mergeMap(action => rejectMergeConflict(action.id)),
    map(incomingRejectMergeConflictResult)
  );

const approveOrRejectMergeConflictErrorToNotice = (
  e: MergeConflictApproveError | MergeConflictRejectError
) => {
  switch (e) {
    case "MergeConflictNotFound":
      return "The merge conflict could not be found";
    case "NotAllVesselIdsAreFound":
      return "The vessel data does not exist anymore";
    default:
      return e;
  }
};

const onIncomingApproveMergeConflictResultEpic: Epic<
  Action,
  Action,
  AppState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, IncomingApproveMergeConflictResult>(
      INCOMING_APPROVE_MERGE_CONFLICT_RESULT
    ),
    mergeMap((result: IncomingApproveMergeConflictResult) => {
      const message =
        (result.errorMessage &&
          approveOrRejectMergeConflictErrorToNotice(
            result.errorMessage as MergeConflictApproveError
          )) ||
        "Merge applied";

      return [
        showNotice(message, !result.success),
        ...(result.success
          ? [loadMergeConflicts(state$.value.mergeConflicts.offset)]
          : []) // Refresh search results on success
      ];
    })
  );

const onIncomingRejectMergeConflictResultEpic: Epic<
  Action,
  Action,
  AppState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, IncomingRejectMergeConflictResult>(
      INCOMING_REJECT_MERGE_CONFLICT_RESULT
    ),
    mergeMap((result: IncomingRejectMergeConflictResult) => {
      const message =
        (result.errorMessage &&
          approveOrRejectMergeConflictErrorToNotice(
            result.errorMessage as MergeConflictRejectError
          )) ||
        "Merge conflict removed";

      return [
        showNotice(message, !result.success),
        ...(result.success
          ? [loadMergeConflicts(state$.value.mergeConflicts.offset)]
          : []) // Refresh search results on success
      ];
    })
  );

export const mergeConflictsEpic = combineEpics(
  loadMergeConflictsEpic,
  searchMergeConflictsEpic,
  approveMergeConflictEpic,
  onIncomingApproveMergeConflictResultEpic,
  rejectMergeConflictEpic,
  onIncomingRejectMergeConflictResultEpic
);
