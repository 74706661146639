import React from "react";
import {Callback} from "../../util/Callback";
import styles from "./Search.module.scss";
import {SearchCriteria} from "../../store/search/search.interfaces";

interface SearchBarProps {
    searchCriteria: SearchCriteria;
    setSearchCriteria: Callback<SearchCriteria>;
}

const SearchBar = ({searchCriteria, setSearchCriteria}: SearchBarProps) => (
    <div className={styles.searchBar}>
        <input
            type="text"
            value={searchCriteria.searchTerm}
            onChange={e =>
                setSearchCriteria({ searchTerm: e.target.value, includeAllSources: searchCriteria.includeAllSources})
            }
        />
        <label className={styles.searchLabel}>All sources</label>
            <input
                type="checkbox"
                checked={searchCriteria.includeAllSources}
                onChange={e =>
                    setSearchCriteria({ searchTerm: searchCriteria.searchTerm, includeAllSources: e.target.checked})
                }
            />

    </div>
);

export default SearchBar;
