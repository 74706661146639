import { SearchState, VesselState } from "./search.interfaces";
import {
  INCOMING_MERGE_RESULT,
  INCOMING_SEARCH_RESULTS,
  MERGE_VESSELS,
  MODIFY_SORT_ORDER,
  SearchAction,
  SET_SEARCH_CRITERIA,
  TOGGLE_VESSEL_EXPANDED,
  TOGGLE_VESSEL_SELECTION,
  UPDATE_MANUAL_DATA,
  UPDATE_MANUAL_DATA_RESULT
} from "./search.actions";
import { VesselDataWithSnapshot } from "../../domain/vesseldata/VesselDataWithSnapshot";
import { Map } from "immutable";
import { VesselUUID } from "../../domain/VesselId";
import { ForceMergeResult } from "../../domain/MergeSummary";

export const emptySearchState: SearchState = {
  searchCriteria: { searchTerm: "", includeAllSources: false },
  searchResults: Map(),
  sortOrder: []
};

function searchResultToVesselState(
  searchResult: VesselDataWithSnapshot
): [VesselUUID, VesselState] {
  return [
    searchResult.vesselData.id,
    {
      id: searchResult.vesselData.id,
      data: searchResult.vesselData,
      properties: searchResult.properties,
      propertiesBySource: searchResult.propertiesBySource,
      snapshot: searchResult.snapshot,
      selected: false,
      expanded: false,
      hasMergeConflict: searchResult.hasMergeConflict,
      mergeConflicts: searchResult.mergeConflicts
    }
  ];
}
export function searchReducer(
  state: SearchState = emptySearchState,
  action: SearchAction
): SearchState {
  switch (action.type) {
    case SET_SEARCH_CRITERIA:
      return { ...state, searchCriteria: action.searchCriteria };
    case INCOMING_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: Map(action.searchResults.map(searchResultToVesselState))
      };
    case TOGGLE_VESSEL_SELECTION:
      return {
        ...state,
        searchResults: state.searchResults.update(action.vesselId, v => ({
          ...v,
          selected: !v.selected
        }))
      };
    case TOGGLE_VESSEL_EXPANDED:
      return {
        ...state,
        searchResults: state.searchResults.update(action.vesselId, v => ({
          ...v,
          expanded: !v.expanded
        }))
      };
    case MODIFY_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.sortOrder
      };
    case UPDATE_MANUAL_DATA:
      return {
        ...state,
        searchResults: state.searchResults.update(action.vesselId, v => ({
          ...v,
          updatedManualData: action.manualData
        }))
      };
    case UPDATE_MANUAL_DATA_RESULT:
      return {
        ...state,
        searchResults: state.searchResults.update(
          action.updatedVessel.vesselData.id,
          v => ({
            ...v,
            data: action.updatedVessel.vesselData,
            properties: action.updatedVessel.properties,
            propertiesBySource: action.updatedVessel.propertiesBySource
          })
        )
      };
    case MERGE_VESSELS:
      return {
        ...state,
        searchResults: preEmptiveMerge(
          state.searchResults,
          action.selectedVessels
        )
      };
    case INCOMING_MERGE_RESULT: {
      return {
        ...state,
        searchResults: processForceMergeResult(
          state.searchResults,
          action.forceMergeResult
        )
      };
    }

    default:
      return state;
  }

  function preEmptiveMerge(
    searchResults: Map<VesselUUID, VesselState>,
    mergedVessels: VesselUUID[]
  ): Map<VesselUUID, VesselState> {
    const vessels = mergedVessels.flatMap(id => {
      const vessel = searchResults.get(id);
      return vessel === undefined ? [] : [vessel];
    });
    const sortedVessels = vessels.sort(
      (v1, v2) =>
        new Date(v2.data.metaData.createdOn).getTime() -
        new Date(v1.data.metaData.createdOn).getTime()
    );

    return searchResults.deleteAll(
      //remove all but the oldest vessel
      sortedVessels.slice(1).map(v => v.id)
    );
  }

  function processForceMergeResult(
    searchResults: Map<VesselUUID, VesselState>,
    {
      vesselData,
      properties,
      merged,
      vesselSnapshot,
      propertiesBySource
    }: ForceMergeResult
  ): Map<VesselUUID, VesselState> {
    const existingOutcome =
      searchResults.get(vesselData.id) ||
      searchResultToVesselState({
        vesselData,
        properties,
        snapshot: vesselSnapshot,
        propertiesBySource,
        hasMergeConflict: false,
        mergeConflicts: []
      })[1];
    return searchResults.deleteAll(merged).set(vesselData.id, {
      ...existingOutcome,
      data: vesselData,
      properties: properties
    });
  }
}
