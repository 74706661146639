import React from "react";
import { Callback } from "../../util/Callback";
import { QueryString } from "../../store/search/search.interfaces";
import styles from "./ChangeHistory.module.scss";

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: Callback<QueryString>;
}

const SearchBar = ({ searchTerm, setSearchTerm }: SearchBarProps) => (
  <div className={styles.searchBar}>
    <input
      type="text"
      value={searchTerm}
      onChange={e => setSearchTerm(e.target.value)}
    />
  </div>
);

export default SearchBar;
