import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { ManualVesselData} from "../../domain/vesseldata/ManualVesselData";
import { AllVesselRoles, VesselRole } from "../../domain/VesselRole";
import {
  AisPositionAllowedReason,
  AllAisPositionAllowedReasons
} from "../../domain/AisPositionAllowedReason";
import { Callback, Callback2 } from "../../util/Callback";
import { VesselUUID } from "../../domain/VesselId";
import {KnownVesselOperators} from "../../domain/VesselOperator";

const True = "true";
const False = "false";
const Unknown = "";

type ProcessInput<T extends keyof ManualVesselData["data"]> = (
  value: string
) => ManualVesselData["data"][T];

export interface EditManualDataFormProps {
  manualData: ManualVesselData;
  updateManualData: Callback2<VesselUUID, ManualVesselData>;
  vesselId: VesselUUID;
}

interface EditManualDataFormState {
  manualData: ManualVesselData;
}

function booleanToString(b?: boolean): string {
  return b === undefined ? Unknown : b.toString();
}

function parseString(s: string): string | undefined {
  if (s === Unknown) {
    return undefined;
  } else {
    return s;
  }
}

function parseVesselRole(s: string): VesselRole | undefined {
  return AllVesselRoles.find(r => (s === r ? r : undefined));
}

function parseAisReason(s: string): AisPositionAllowedReason | undefined {
  return AllAisPositionAllowedReasons.find(r => (s === r ? r : undefined));
}

function parseOperator(s: string): string | undefined {
  return KnownVesselOperators.find(r => (s === r ? r : undefined));
}

function parseNumber(s: string): number | undefined {
  return parseInt(s);
}

function parseBoolean(s: string): boolean | undefined {
  switch (s) {
    case True:
      return true;
    case False:
      return false;
    case Unknown:
      return undefined;
    default:
      console.warn("Cannot parse boolean input: " + s);
      return undefined;
  }
}

export class EditManualDataForm extends Component<
  EditManualDataFormProps,
  EditManualDataFormState
> {
  constructor(props: EditManualDataFormProps) {
    super(props);
    this.state = {
      manualData: this.props.manualData
    };
  }

  private onFieldChange<K extends keyof ManualVesselData["data"]>(
    k: K,
    processInput: ProcessInput<K>
  ): Callback<React.ChangeEvent<HTMLInputElement>> {
    return e => {
      const newValue = processInput(e.target.value);
      this.setState({
        ...this.state,
        manualData: {
          ...this.state.manualData,
          data: {
            ...this.state.manualData.data,
            [k]: newValue
          }
        }
      });
    };
  }

  private onSubmit = () => {
    this.props.updateManualData(this.props.vesselId, this.state.manualData);
  };

  render() {
    const manualData = this.state.manualData.data;
    return (
      <Form>
        <FormGroup row>
          <Label for="name" sm={2}>
            Name
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="name"
              id="name"
              value={manualData.name || Unknown}
              onChange={this.onFieldChange("name", parseString)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="imo" sm={2}>
            IMO
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="imo"
              id="imo"
              value={manualData.imo || Unknown}
              onChange={this.onFieldChange("imo", parseString)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="eni" sm={2}>
            ENI
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="eni"
              id="eni"
              value={manualData.eni || Unknown}
              onChange={this.onFieldChange("eni", parseString)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="uscg" sm={2}>
            USCG
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="uscg"
              id="uscg"
              value={manualData.uscg || Unknown}
              onChange={this.onFieldChange("uscg", parseString)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="mmsi" sm={2}>
            MMSI
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="mmsi"
              id="mmsi"
              value={manualData.mmsi || Unknown}
              onChange={this.onFieldChange("mmsi", parseString)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="callSign" sm={2}>
            Call sign
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="callSign"
              id="callSign"
              value={manualData.callSign || Unknown}
              onChange={this.onFieldChange("callSign", parseString)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="vesselRole" sm={2}>
            Vessel role
          </Label>
          <Col sm={10}>
            <Input
              type="select"
              name="vesselRole"
              id="vesselRole"
              value={manualData.vesselRole || Unknown}
              onChange={this.onFieldChange("vesselRole", parseVesselRole)}
            >
              <option key={Unknown}>Unknown</option>
              {AllVesselRoles.map(role => {
                return <option key={role}>{role}</option>;
              })}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="length" sm={2}>
            Length
          </Label>
          <Col sm={10}>
            <Input
              type="number"
              name="length"
              id="length"
              onChange={this.onFieldChange("length", parseNumber)}
              value={manualData.length ? manualData.length + "" : Unknown}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="beam" sm={2}>
            Beam
          </Label>
          <Col sm={10}>
            <Input
              type="number"
              name="beam"
              id="beam"
              value={manualData.beam ? manualData.beam + "" : Unknown}
              onChange={this.onFieldChange("beam", parseNumber)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="draught" sm={2}>
            Draught
          </Label>
          <Col sm={10}>
            <Input
              type="number"
              name="draught"
              id="draught"
              value={manualData.draught ? manualData.draught + "" : Unknown}
              onChange={this.onFieldChange("draught", parseNumber)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="aisReason" sm={2}>
            Ais position allowed reason
          </Label>
          <Col sm={10}>
            <Input
              type="select"
              name="aisReason"
              id="aisReason"
              onChange={this.onFieldChange("aisReason", parseAisReason)}
              value={manualData.aisReason || Unknown}
            >
              <option key={Unknown}>Unknown</option>
              {AllAisPositionAllowedReasons.map(reason => {
                return <option key={reason}>{reason}</option>;
              })}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="outOfService" sm={2}>
            Out of service
          </Label>
          <Col sm={10}>
            <Input
              type="select"
              name="outOfService"
              id="outOfService"
              onChange={this.onFieldChange("outOfService", parseBoolean)}
              value={booleanToString(manualData.outOfService)}
            >
              <option key={True} value={True}>
                Yes
              </option>
              <option key={False} value={False}>
                No
              </option>
              <option key={Unknown} value={Unknown}>
                Not set
              </option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="invalid" sm={2}>
            Invalid
          </Label>
          <Col sm={10}>
            <Input
              type="select"
              name="invalid"
              id="invalid"
              onChange={this.onFieldChange("invalid", parseBoolean)}
              value={booleanToString(manualData.invalid)}
            >
              <option key={True} value={True}>
                Yes
              </option>
              <option key={False} value={False}>
                No
              </option>
              <option key={Unknown} value={Unknown}>
                Not set
              </option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="comment" sm={2}>
            Comment
          </Label>
          <Col sm={10}>
            <Input
              type="textarea"
              name="comment"
              id="comment"
              value={manualData.comment || Unknown}
              onChange={this.onFieldChange("comment", parseString)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="capacityContainers" sm={2}>
            Container Capacity (TEU)
          </Label>
          <Col sm={10}>
            <Input
              type="number"
              name="capacityContainers"
              id="capacityContainers"
              value={
                manualData.capacityContainers
                  ? manualData.capacityContainers + ""
                  : Unknown
              }
              onChange={this.onFieldChange("capacityContainers", parseNumber)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="isDeepSeaVessel" sm={2}>
            Is Deep Sea Vessel
          </Label>
          <Col sm={10}>
            <Input
              type="select"
              name="isDeepSeaVessel"
              id="isDeepSeaVessel"
              onChange={this.onFieldChange("isDeepSeaVessel", parseBoolean)}
              value={booleanToString(manualData.isDeepSeaVessel)}
            >
              <option key={True} value={True}>
                Yes
              </option>
              <option key={False} value={False}>
                No
              </option>
              <option key={Unknown} value={Unknown}>
                Not set
              </option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="operator" sm={2}>
            Operator
          </Label>
          <Col sm={10}>
            <Input
                type="select"
                name="operator"
                id="operator"
                value={manualData.operator || Unknown}
                onChange={this.onFieldChange("operator", parseOperator)}
            >
              <option key={Unknown}>Unknown</option>
              {KnownVesselOperators.map(role => {
                return <option key={role}>{role}</option>;
              })}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup check row>
          <Col sm={{ size: 10, offset: 2 }}>
            <Button onClick={() => this.onSubmit()}>Save</Button>
          </Col>
        </FormGroup>
      </Form>
    );
  }
}
