import React from "react";
import { SearchContainer } from "../Search/Search";
import { MergeConflictListContainer } from "../MergeConflicts/MergeConflictList";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { Router } from "react-router";

import styles from "./App.module.scss";
import { ChangeHistoryContainer } from "../ChangeHistory/ChangeHistory";
import { NoticeSnackbarContainer } from "../Notice/NoticeSnackbar";
import { ChangeHistoryListContainer } from "../ChangeHistory/ChangeHistoryList";
import { ImportVesselsContainer } from "../Import/ImportVessels";
import { PAGES } from "../../constants/pages";
import { LoginContainer } from "../Login/Login.container";
import { Header } from "../Header/Header";
import Logout from "../Login/Logout";
import history from "../../util/History";
import { tokenStorage } from "../Login/TokenStorage";

const App: React.FC = () => (
  <Router history={history}>
    <div className={styles.app}>
      <Header />
      <NoticeSnackbarContainer />

      <div className={styles.tabButtons}>
        <NavLink
          to="/"
          className={styles.tabButton}
          activeClassName={styles.active}
          exact
        >
          Change History
        </NavLink>
        <NavLink
          to="/search"
          className={styles.tabButton}
          activeClassName={styles.active}
        >
          Search
        </NavLink>
        <NavLink
          to="/mergeConflicts"
          className={styles.tabButton}
          activeClassName={styles.active}
        >
          Merge Conflicts
        </NavLink>
        <NavLink
          to="/import"
          className={styles.tabButton}
          activeClassName={styles.active}
        >
          Import
        </NavLink>
        <NavLink to="/logout" className={styles.tabButton}>
          Log out
        </NavLink>
      </div>
      <>
        <Switch>
          <Route path={PAGES.login} component={LoginContainer} />
          <PrivateRoute path={PAGES.search} component={SearchContainer} />
          <PrivateRoute
            path={PAGES.mergeConflicts}
            component={MergeConflictListContainer}
          />
          <PrivateRoute
            path={PAGES.history}
            component={ChangeHistoryContainer}
          />
          <PrivateRoute
            path={PAGES.explorer}
            exact
            component={ChangeHistoryListContainer}
          />
          <PrivateRoute
            path={PAGES.import}
            exact
            component={ImportVesselsContainer}
          />
          <PrivateRoute path={PAGES.logout} exact component={Logout} />
        </Switch>
      </>
    </div>
  </Router>
);

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const render = (props: any) => {
    if (tokenStorage.isAuthenticated()) {
      return <Component {...props} />;
    } else {
      return (
        <Redirect
          to={{ pathname: PAGES.login, state: { from: props.location } }}
        />
      );
    }
  };

  return <Route {...rest} render={render} />;
};

export default App;
