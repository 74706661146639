import { combineEpics, Epic, ofType } from "redux-observable";
import { debounce, filter, map, mergeMap } from "rxjs/operators";
import {
  ChangeHistoryAction,
  incomingChangeHistory,
  incomingChangeHistoryList,
  incomingChangeHistorySearchResult,
  LOAD_CHANGE_HISTORY,
  LOAD_CHANGE_HISTORY_LIST,
  LoadChangeHistory,
  LoadChangeHistoryList,
  SET_CHANG_HISTORY_SEARCH_TERM,
  SetSearchTerm
} from "./changeHistory.actions";
import {
  fetchAllChangeHistory,
  fetchChangeHistory,
  search
} from "./changeHistory.fetch";
import { interval } from "rxjs";

export const changeHistoryEpic: Epic<ChangeHistoryAction> = action$ =>
  action$.pipe(
    ofType<ChangeHistoryAction, LoadChangeHistory>(LOAD_CHANGE_HISTORY),
    mergeMap(action => fetchChangeHistory(action.vesselId)),
    map(incomingChangeHistory)
  );

export const changeHistoryListEpic: Epic<ChangeHistoryAction> = action$ =>
  action$.pipe(
    ofType<ChangeHistoryAction, LoadChangeHistoryList>(
      LOAD_CHANGE_HISTORY_LIST
    ),
    mergeMap(action => fetchAllChangeHistory(action.offset, action.size)),
    map(incomingChangeHistoryList)
  );

const searchTermEpic: Epic<ChangeHistoryAction> = action$ =>
  action$.pipe(
    ofType<ChangeHistoryAction, SetSearchTerm>(SET_CHANG_HISTORY_SEARCH_TERM),
    filter(action => action.searchTerm.length >= 3),
    debounce(() => interval(300)),
    mergeMap(action => search(action.searchTerm)),
    map(incomingChangeHistorySearchResult)
  );

export const historyEpic = combineEpics(
  changeHistoryEpic,
  changeHistoryListEpic,
  searchTermEpic
);
