import styles from "./MergeConflicts.module.scss";
import ReactJson from "react-json-view";
import React from "react";
import { VesselData } from "../../domain/vesseldata/VesselData";

interface MergeConflictViewProps {
  selectedMergee?: VesselData;
  selectedInto?: VesselData;
}

export const MergeConflictView = ({
  selectedMergee,
  selectedInto
}: MergeConflictViewProps) =>
  selectedInto && selectedMergee ? (
    <div className={styles.mergeConflictView}>
      <div className={styles.mergeConflictViewLeftPane}>
        Mergee:
        <ReactJson
          src={selectedMergee}
          enableClipboard={false}
          displayDataTypes={false}
          sortKeys={true}
          displayObjectSize={false}
        />
      </div>
      <div style={{ width: "50%", float: "right" }}>
        Into:
        <ReactJson
          src={selectedInto}
          enableClipboard={false}
          displayDataTypes={false}
          sortKeys={true}
          displayObjectSize={false}
        />
      </div>
    </div>
  ) : (
    <div>NO DATA</div>
  );
