import { VesselData } from "../../domain/vesseldata/VesselData";
import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ReactJson from "react-json-view";

type RawDataModalProps = Readonly<{
  rawData?: VesselData;
  onClose: () => void;
}>;

export class RawDataModal extends Component<RawDataModalProps> {
  render() {
    return (
      <div>
        <Modal isOpen={!!this.props.rawData} size="lg">
          <ModalHeader toggle={this.props.onClose}>Raw data</ModalHeader>
          <ModalBody>
            {this.props.rawData && (
              <ReactJson
                src={this.props.rawData}
                enableClipboard={false}
                displayDataTypes={false}
                sortKeys={true}
                displayObjectSize={false}
              />
            )}{" "}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
