import React from "react";
import ReactDOM from "react-dom";

import App from "./components/App/App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./stylesheets/main.scss";
import { Provider } from "react-redux";
import { configureStore } from "./store/configureStore";

const app = (
  <Provider store={configureStore()}>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
