import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { AppState } from "../../store/AppState";
import { VesselUUID } from "../../domain/VesselId";
import { VesselDataChange } from "../../domain/VesselDataChange";
import { Action, Dispatch } from "redux";
import {
  loadChangeHistory,
  viewChangeHistory
} from "../../store/changeHistory/changeHistory.actions";
import { connect } from "react-redux";
import ReactTable, { Column } from "react-table";
import { ChangeHistoryItemModal } from "./ChangeHistoryItemModal";

interface ChangeHistoryProps extends RouteComponentProps<{ id: VesselUUID }> {
  changeHistory: VesselDataChange[];
  showItem?: VesselDataChange;
  onLoad: (vesselId: VesselUUID) => void;
  onShowItem: (item?: VesselDataChange) => void;
}

export class ChangeHistory extends Component<ChangeHistoryProps> {
  componentDidMount(): void {
    this.props.onLoad(this.props.match.params.id);
  }

  showModal(item: VesselDataChange) {
    this.props.onShowItem(item);
  }

  hideModal() {
    this.props.onShowItem(undefined);
  }

  render() {
    const columns: Column<VesselDataChange>[] = [
      { Header: "Date", accessor: "recordTime" },
      { Header: "Type", accessor: "changeType" },
      { Header: "Author", accessor: "author" },
      {
        Header: "Snapshot changed",
        accessor: d => d.snapshotChanged && (d.snapshotChanged ? "Yes" : "No"),
        id: "snapshotChanged"
      },
      {
        Header: "",
        Cell: row => (
          <div onClick={() => this.showModal(row.original)}>View</div>
        )
      }
    ];

    return (
      <>
        {this.props && this.props.showItem && (
          <ChangeHistoryItemModal
            history={this.props.showItem}
            onClose={() => this.hideModal()}
          />
        )}
        <ReactTable
          data={this.props.changeHistory}
          columns={columns}
          className="-striped -highlight"
        />
      </>
    );
  }
}

function mapStateToProps(
  state: AppState
): Pick<ChangeHistoryProps, "changeHistory" | "showItem"> {
  return {
    changeHistory: state.changeHistory.history,
    showItem: state.changeHistory.showItem
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<Action>
): Pick<ChangeHistoryProps, "onLoad" | "onShowItem"> {
  return {
    onLoad: (vesselId: VesselUUID) => {
      dispatch(loadChangeHistory(vesselId));
    },
    onShowItem: (item?: VesselDataChange) => {
      dispatch(viewChangeHistory(item));
    }
  };
}

export const ChangeHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeHistory);
