import { VesselUUID } from "../../domain/VesselId";
import { AdministrationAction } from "./administration.actions";
export interface RecalculatedVesselState {
  recalculatedVessels: VesselUUID[];
}
export const emptyRecalculatedState: RecalculatedVesselState = {
  recalculatedVessels: []
};

export function administrationReducer(
  state: RecalculatedVesselState = emptyRecalculatedState,
  action: AdministrationAction
): RecalculatedVesselState {
  if (action.type === "INCOMING_RECALCULATE_VESSEL") {
    return { ...state, recalculatedVessels: [action.id] };
  } else {
    return state;
  }
}
