import { MergeConflict, MergeConflictID } from "../../domain/MergeConflict";
import {
  ApproveMergeConflictResult,
  RejectMergeConflictResult
} from "./mergeConflicts.interfaces";
import { createRequest } from "../../util/Login";
import { QueryString } from "../search/search.interfaces";

export function searchMergeConflicts(offset: number): Promise<MergeConflict[]> {
  return fetch(
    `/api/mergeConflicts?offset=${encodeURIComponent(offset)}`,
    createRequest("GET")
  )
    .then(response => response.json())
    .then(x => {
      return x;
    })
    .catch(e => {
      console.warn(e);
      return [];
    });
}

export function searchMergeConflictsByQuery(
  query: QueryString
): Promise<MergeConflict[]> {
  return fetch(
    `/api/mergeConflicts/${encodeURIComponent(query)}`,
    createRequest("GET")
  )
    .then(response => response.json())
    .then(x => {
      return x;
    })
    .catch(e => {
      console.warn(e);
      return [];
    });
}

export function approveMergeConflict(
  id: MergeConflictID
): Promise<ApproveMergeConflictResult> {
  return fetch(
    `/api/mergeConflicts/${encodeURIComponent(id)}/approve`,
    createRequest("POST")
  ).then(response => {
    if (response.status === 200) {
      return Promise.resolve({ id, success: true, errorMessage: undefined });
    } else {
      return response
        .json()
        .catch(e => {
          console.warn(e);
          return "Server error";
        })
        .then(errorMessage => {
          return { id, success: false, errorMessage };
        });
    }
  });
}

export function rejectMergeConflict(
  id: MergeConflictID
): Promise<RejectMergeConflictResult> {
  return fetch(
    `/api/mergeConflicts/${encodeURIComponent(id)}`,
    createRequest("DELETE")
  ).then(response => {
    if (response.status === 200) {
      return Promise.resolve({ id, success: true, errorMessage: undefined });
    } else {
      return response
        .json()
        .catch(e => {
          console.warn(e);
          return "Server error";
        })
        .then(errorMessage => {
          return { id, success: false, errorMessage };
        });
    }
  });
}
