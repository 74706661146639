import { ImportVesselsState } from "./import.interfaces";
import {
  ImportVesselsAction,
  INCOMING_IMPORT_RESULT,
  SEND_VESSELS_FOR_IMPORT
} from "./import.actions";

export const emptyImportVesselsState: ImportVesselsState = {
  pendingImport: false
};

export function importVesselsReducer(
  state: ImportVesselsState = emptyImportVesselsState,
  action: ImportVesselsAction
): ImportVesselsState {
  switch (action.type) {
    case SEND_VESSELS_FOR_IMPORT:
      return { ...state, pendingImport: true, importResult: undefined };
    case INCOMING_IMPORT_RESULT:
      return { ...state, pendingImport: false, importResult: action.data };
    default:
      return state;
  }
}
