import React, { Component } from "react";
import { AppState } from "../../store/AppState";
import { VesselDataChange } from "../../domain/VesselDataChange";
import { Action, Dispatch } from "redux";
import {
  loadChangeHistoryList,
  setSearchTerm,
  viewChangeHistory
} from "../../store/changeHistory/changeHistory.actions";
import { connect } from "react-redux";
import ReactTable, { Column } from "react-table";
import { ChangeHistoryItemModal } from "./ChangeHistoryItemModal";
import { Callback, Callback2 } from "../../util/Callback";
import styles from "../ChangeHistory/ChangeHistory.module.scss";
import SearchBar from "../ChangeHistory/SearchBar";
import { QueryString } from "../../store/search/search.interfaces";

interface ChangeHistoryListProps {
  changeHistory: VesselDataChange[];
  showItem?: VesselDataChange;
  offset: number;
  size: number;
  onLoad: Callback2<number, number>;
  onShowItem: (item?: VesselDataChange) => void;
  searchTerm: QueryString;
  setSearchTerm: Callback<QueryString>;
}

export const defaultOffset = 0;
export const defaultSize = 20;

export class ChangeHistoryList extends Component<ChangeHistoryListProps> {
  componentDidMount(): void {
    this.props.onLoad(defaultOffset, defaultSize);
  }

  showModal(item: VesselDataChange) {
    this.props.onShowItem(item);
  }

  hideModal() {
    this.props.onShowItem(undefined);
  }

  render() {
    const columns: Column<VesselDataChange>[] = [
      { Header: "Date", accessor: "recordTime" },
      { id: "name", Header: "Name", accessor: v => v.name },
      { id: "mmsi", Header: "MMSI", accessor: v => v.mmsi },
      { Header: "Type", accessor: "changeType" },
      { Header: "Author", accessor: "author" },
      {
        Header: "Snapshot changed",
        accessor: d => d.snapshotChanged && (d.snapshotChanged ? "Yes" : "No"),
        id: "snapshotChanged"
      },
      {
        Header: "",
        Cell: row => (
          <div onClick={() => this.showModal(row.original)}>View</div>
        )
      }
    ];

    return (
      <>
        {this.props && this.props.showItem && (
          <ChangeHistoryItemModal
            history={this.props.showItem}
            onClose={() => this.hideModal()}
          />
        )}
        <div className={styles.searchMenu}>
          <SearchBar
            searchTerm={this.props.searchTerm}
            setSearchTerm={this.props.setSearchTerm}
          />
        </div>
        <ReactTable
          data={this.props.changeHistory}
          columns={columns}
          className="-striped -highlight"
          pageSize={defaultSize}
          pages={100}
          manual // Manual pagination
          showPageSizeOptions={false}
          showPageJump={false}
          onFetchData={(state, instance) =>
            this.props.onLoad(state.page * state.pageSize, state.pageSize)
          }
        />
      </>
    );
  }
}

function mapStateToProps(
  state: AppState
): Pick<ChangeHistoryListProps, "changeHistory" | "showItem" | "searchTerm"> {
  return {
    changeHistory: state.changeHistoryList.history,
    showItem: state.changeHistory.showItem,
    searchTerm: state.changeHistoryList.searchTerm
  };
}

function mapDispatchTo(
  dispatch: Dispatch<Action>
): Pick<ChangeHistoryListProps, "onLoad" | "onShowItem" | "setSearchTerm"> {
  return {
    onLoad: (offset: number, size: number) => {
      dispatch(loadChangeHistoryList(offset, size));
    },
    onShowItem: (item?: VesselDataChange) => {
      dispatch(viewChangeHistory(item));
    },
    setSearchTerm: (searchTerm: QueryString) => {
      dispatch(setSearchTerm(searchTerm));
    }
  };
}

export const ChangeHistoryListContainer = connect(
  mapStateToProps,
  mapDispatchTo
)(ChangeHistoryList);
