import { createRequest } from "../../util/Login";
import {
  ImportVesselsData,
  ImportVesselsImportResult
} from "./import.interfaces";
import { InternalVesselSnapshot } from "../../domain/vesseldata/VesselSnapshot";

export function importVesselsRequest(
  data: ImportVesselsData
): Promise<ImportVesselsImportResult> {
  const body = JSON.stringify(data);
  return fetch("/api/vessels/import", createRequest("POST", body))
    .then(handleResponse)
    .catch(
      (e: any): ImportVesselsImportResult => {
        console.log(e);
        return {
          status: "failure",
          response: { error: `unknown error: ${e}` }
        };
      }
    );
}

const handleResponse: (
  response: Response
) => Promise<ImportVesselsImportResult> = response => {
  if (response.status === 200) {
    return response.json().then(body => {
      return {
        status: "processed",
        response: body as InternalVesselSnapshot[]
      };
    });
  } else {
    return response.json().then(body => {
      return {
        status: "failure",
        response: body as { error: string; invalidRows?: [number, string][] }
      };
    });
  }
};
