import * as React from "react";
import styles from "./Login.module.scss";
import { signIn } from "./CognitoService";
import { useState } from 'react';
import { tokenStorage } from './TokenStorage';
import history from "../../util/History";
import {PAGES} from "../../constants/pages";



const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (emailError) setEmailError('');
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (passwordError) setPasswordError('');
  };

  const validateForm = () => {
    let valid = true;
    if (!email) {
      setEmailError('Email is required');
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Invalid email address');
      valid = false;
    }

    if (!password) {
      setPasswordError('Password is required');
      valid = false;
    }

    return valid;
  };


  const handleSignIn = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const session = await signIn(email, password);
        if (session && typeof session.AccessToken !== 'undefined') {
          tokenStorage.saveToken(session.AccessToken, session.ExpiresIn);
          if (tokenStorage.isAuthenticated()) {
            history.push(PAGES.search);
          } else {
            console.log("Login Failed")
          }
        }
      } catch (error) {
        alert(`Sign in failed: ${error}`);
      }
    }
  };


  return (
      <>
        <div className={styles.leftpanel} />
        <div className={styles.rightpanel}>
          <div className={styles.vmdbheader}>
            <span>Vessel master database</span>
          </div>
          <div className={styles.textblock}>
            <div className={styles.signin}>
              {" "}
              Log in
              <a
                  href="mailto:techadmin@port-xchange.com?subject=Access%20to%20VMDB"
                  className={styles.signup}
              >
                Or sign up here
              </a>
            </div>

            <form onSubmit={handleSignIn}>
              <div className="row mb-3">
                <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
                <div className="col-sm-10">
                  <input className={emailError ? "form-control border-danger" : "form-control border-primary"}
                         type="email"
                         id="email"
                         value={email}
                         onChange={handleEmailChange}
                         placeholder="Email"
                         required/>
                  {emailError && <div className="invalid text-danger">{emailError}</div>}
                </div>
              </div>
              <div className="row mb-5">
                <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
                <div className="col-sm-10">
                  <input className={passwordError ? "form-control border-danger" : "form-control border-primary"}
                         type="password"
                         id="password"
                         value={password}
                         onChange={handlePasswordChange}
                         placeholder="Password"
                         required/>
                  {passwordError && <div className="invalid text-danger">{passwordError}</div>}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-10 offset-sm-2">
                  <button onSubmit={handleSignIn} className="btn btn-primary w-100 float-end">Log in</button>
                </div>
              </div>
            </form>
            <div>To sign up send an email to: <div className={styles.signup}>techadmin@port-xchange.com</div></div>
          </div>
        </div>
      </>
  );
}

export default LoginPage;
