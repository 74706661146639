export const TugBoat = "TugBoat";
export const PilotBoat = "PilotBoat";
export const BunkerShip = "BunkerShip";
export const DredgingShip = "DredgingShip";
export const WaterSupplyShip = "WaterSupplyShip";
export const SlopsShip = "SlopsShip";
export const LinesmenBoat = "LinesmenBoat";
export const WasteShip = "WasteShip";
export const CraneShip = "CraneShip";
export const WaterPoliceVessel = "WaterPoliceVessel";
export const PortAuthorityVessel = "PortAuthorityVessel";
export const Tender = "Tender";
export const CustomsVessel = "CustomsVessel";
export const CoastguardVessel = "CoastguardVessel";
export const WaterwayManagementVessel = "WaterwayManagementVessel";
export const TowBoat = "TowBoat";
export const ProvisionSupplyShip = "ProvisionSupplyShip";

export type VesselRole =
  | typeof TugBoat
  | typeof PilotBoat
  | typeof BunkerShip
  | typeof DredgingShip
  | typeof WaterSupplyShip
  | typeof SlopsShip
  | typeof LinesmenBoat
  | typeof WasteShip
  | typeof CraneShip
  | typeof WaterPoliceVessel
  | typeof PortAuthorityVessel
  | typeof Tender
  | typeof CustomsVessel
  | typeof CoastguardVessel
  | typeof WaterwayManagementVessel
  | typeof TowBoat
  | typeof ProvisionSupplyShip ;

export const AllVesselRoles: VesselRole[] = [
  TugBoat,
  PilotBoat,
  BunkerShip,
  DredgingShip,
  WaterSupplyShip,
  SlopsShip,
  LinesmenBoat,
  WasteShip,
  CraneShip,
  WaterPoliceVessel,
  PortAuthorityVessel,
  Tender,
  CustomsVessel,
  CoastguardVessel,
  WaterwayManagementVessel,
  TowBoat,
  ProvisionSupplyShip
];
