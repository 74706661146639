import { MergeConflict, MergeConflictID } from "../../domain/MergeConflict";
import {
  ApproveMergeConflictResult,
  RejectMergeConflictResult
} from "./mergeConflicts.interfaces";

export const LOAD_MERGE_CONFLICTS = "LOAD_MERGE_CONFLICTS";
export const SET_CONFLICT_SEARCH_TERM = "SET_CONFLICT_SEARCH_TERM";
export const INCOMING_MERGE_CONFLICTS = "INCOMING_MERGE_CONFLICTS";
export const APPROVE_MERGE_CONFLICT = "APPROVE_MERGE_CONFLICT";
export const INCOMING_APPROVE_MERGE_CONFLICT_RESULT =
  "INCOMING_APPROVE_MERGE_CONFLICT_RESULT";
export const REJECT_MERGE_CONFLICT = "REJECT_MERGE_CONFLICT";
export const INCOMING_REJECT_MERGE_CONFLICT_RESULT =
  "INCOMING_REJECT_MERGE_CONFLICT_RESULT";

export interface LoadMergeConflicts {
  type: typeof LOAD_MERGE_CONFLICTS;
  offset: number;
}

export function loadMergeConflicts(offset: number): LoadMergeConflicts {
  return { type: LOAD_MERGE_CONFLICTS, offset };
}

export interface SetSearchTerm {
  type: typeof SET_CONFLICT_SEARCH_TERM;
  searchTerm: string;
}

export function setSearchTerm(searchTerm: string): SetSearchTerm {
  return { type: SET_CONFLICT_SEARCH_TERM, searchTerm };
}

export interface IncomingMergeConflicts {
  type: typeof INCOMING_MERGE_CONFLICTS;
  mergeConflicts: MergeConflict[];
}

export function incomingMergeConflicts(
  mergeConflicts: MergeConflict[]
): IncomingMergeConflicts {
  return { type: INCOMING_MERGE_CONFLICTS, mergeConflicts: mergeConflicts };
}

export interface ApproveMergeConflict {
  type: typeof APPROVE_MERGE_CONFLICT;
  id: MergeConflictID;
}

export function approveMergeConflict(
  id: MergeConflictID
): ApproveMergeConflict {
  return { type: APPROVE_MERGE_CONFLICT, id: id };
}

export interface IncomingApproveMergeConflictResult {
  type: typeof INCOMING_APPROVE_MERGE_CONFLICT_RESULT;
  id: MergeConflictID;
  success: boolean;
  errorMessage?: string;
}

export function incomingApproveMergeConflictResult({
  errorMessage,
  id,
  success
}: ApproveMergeConflictResult): IncomingApproveMergeConflictResult {
  return {
    type: INCOMING_APPROVE_MERGE_CONFLICT_RESULT,
    id,
    success,
    errorMessage
  };
}

export interface RejectMergeConflict {
  type: typeof REJECT_MERGE_CONFLICT;
  id: MergeConflictID;
}

export function rejectMergeConflict(id: MergeConflictID): RejectMergeConflict {
  return { type: REJECT_MERGE_CONFLICT, id: id };
}

export interface IncomingRejectMergeConflictResult {
  type: typeof INCOMING_REJECT_MERGE_CONFLICT_RESULT;
  id: MergeConflictID;
  success: boolean;
  errorMessage?: string;
}

export function incomingRejectMergeConflictResult({
  errorMessage,
  id,
  success
}: RejectMergeConflictResult): IncomingRejectMergeConflictResult {
  return {
    type: INCOMING_REJECT_MERGE_CONFLICT_RESULT,
    id,
    success,
    errorMessage
  };
}

export type MergeConflictsAction =
  | LoadMergeConflicts
  | SetSearchTerm
  | IncomingMergeConflicts
  | ApproveMergeConflict
  | IncomingApproveMergeConflictResult
  | RejectMergeConflict
  | IncomingRejectMergeConflictResult;
