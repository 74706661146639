import React from "react";
import { PAGES } from "../../constants/pages";
import { Redirect } from "react-router-dom";
import { tokenStorage } from "./TokenStorage";

class Logout extends React.Component {
  logout = () => {
    //TODO: delete on cognito side as well
    tokenStorage.deleteToken();
  }

  public render() {
    this.logout();
    return <Redirect to={PAGES.explorer} push={true} />;
  }
}

export default Logout;
