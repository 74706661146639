import {
  ImportVesselsData,
  ImportVesselsImportResult
} from "./import.interfaces";

export const SEND_VESSELS_FOR_IMPORT = "SEND_VESSELS_FOR_IMPORT";
export const INCOMING_IMPORT_RESULT = "INCOMING_IMPORT_RESULT";

export interface SendVesselsForImportAction {
  type: typeof SEND_VESSELS_FOR_IMPORT;
  data: ImportVesselsData;
}
export function sendVesselsForImport(
  data: ImportVesselsData
): SendVesselsForImportAction {
  return { type: SEND_VESSELS_FOR_IMPORT, data: data };
}

export interface IncomingImportResultAction {
  type: typeof INCOMING_IMPORT_RESULT;
  data: ImportVesselsImportResult;
}
export function incomingImportResult(
  importResult: ImportVesselsImportResult
): IncomingImportResultAction {
  return { type: INCOMING_IMPORT_RESULT, data: importResult };
}

export type ImportVesselsAction =
  | SendVesselsForImportAction
  | IncomingImportResultAction;
