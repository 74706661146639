import { combineEpics, Epic, ofType } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import {
  ImportVesselsAction,
  incomingImportResult,
  SEND_VESSELS_FOR_IMPORT,
  SendVesselsForImportAction
} from "./import.actions";
import { importVesselsRequest } from "./import.fetch";

const sendImportDataEpic: Epic<ImportVesselsAction> = action$ =>
  action$.pipe(
    ofType<ImportVesselsAction, SendVesselsForImportAction>(
      SEND_VESSELS_FOR_IMPORT
    ),
    mergeMap(action => importVesselsRequest(action.data)),
    map(incomingImportResult)
  );

export const importVesselsEpic = combineEpics(sendImportDataEpic);
