// Functionality for parsing vessel CSV/TSV with a header

import {ImportRow} from '../../store/import/import.interfaces'

export interface ColumnIndices {
  imo?: number
  eni?: number
  mmsi?: number
  name?: number
  callSign?: number
}

export interface ParsedCSV {
  indices: ColumnIndices
  header: string[]
  rows: string[][]
}

export function parseCSV(data: string): ParsedCSV {
  let rows = split(data)
  let header = rows.shift() || []

  let indices: ColumnIndices = {
    imo: findIndex(header, /imo/i),
    eni: findIndex(header, /eni/i),
    mmsi: findIndex(header, /mmsi/i),
    name: findIndex(header, /name/i),
    callSign: findIndex(header, /call\s*sign/i)
  }

  return {indices, header, rows}
}

export function csvToImportRow(csv: ParsedCSV): ImportRow[] {
  let idx = csv.indices
  return csv.rows.map(row => {
    return {
      raw: row,
      imo: getColumn(idx.imo, row),
      eni: getColumn(idx.eni, row),
      mmsi: getColumn(idx.mmsi, row),
      callSign: getColumn(idx.callSign, row),
      name: getColumn(idx.name, row)
    }
  })
}



function split(data: string): string[][] {
  return data.split('\n').map(line => line.split(/[\t,]/))
}

// Try to match the regexes in order
function findIndex(array: string[],regex: RegExp): number | undefined {
  let idx = array.findIndex(s => s.match(regex))
  return idx === -1 ? undefined : idx
}

function getColumn(index: number | undefined, row: string[]): string | undefined {
  let trimmed = index !== undefined ? row[index]?.trim() : undefined
  return trimmed !== "" ? trimmed : undefined
}