import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { AppState } from "../../store/AppState";
import { Action, Dispatch } from "redux";
import { connect } from "react-redux";
import { hideNotice } from "../../store/notice/notice.actions";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import styles from "./Notice.module.scss";

export interface SnackbarProps {
  open: boolean;
  success: boolean;
  message?: String;
  onClose: () => void;
}

function NoticeSnackbar(props: SnackbarProps) {
  const Icon = props.success ? CheckCircleIcon : ErrorIcon;

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={props.open}
        autoHideDuration={10000}
        onClose={props.onClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
      >
        <SnackbarContent
          message={
            <span id="message-id">
              <Icon />
              {props.message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              className={styles.close}
              color="inherit"
              onClick={props.onClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        ></SnackbarContent>
      </Snackbar>
    </div>
  );
}

function mapStateToProps(
  state: AppState
): Pick<SnackbarProps, "open" | "success" | "message"> {
  return {
    open: state.notice !== null,
    message: state.notice ? state.notice.message : "",
    success: state.notice ? !state.notice.error : false
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<Action>
): Pick<SnackbarProps, "onClose"> {
  return {
    onClose: () => dispatch(hideNotice())
  };
}

export const NoticeSnackbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NoticeSnackbar);
