import React, { Component } from "react";
import {
  SearchResultSnapshot,
  SearchResultSnapshots,
  VesselDataSource
} from "./search.helpers";
import ReactTable, { Column } from "react-table";
import styles from "./Search.module.scss";
import { ManualVesselData } from "../../domain/vesseldata/ManualVesselData";
import { Callback, Callback2, Callback3 } from "../../util/Callback";
import { VesselUUID } from "../../domain/VesselId";
import { Link } from "react-router-dom";
import { VesselData } from "../../domain/vesseldata/VesselData";
import { RawDataModal } from "./RawDataModal";
import { EditManualDataModal } from "./EditManualDataModal";
import { ViewSnapshotModal } from "./ViewSnapshotModal";
import { VesselSnapshot } from "../../domain/vesseldata/VesselSnapshot";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { MergeConflict, MergeConflictID } from "../../domain/MergeConflict";
import { MergeConflictsModal } from "./MergeConflictsModal";

interface SearchResultRowProps {
  searchResultSnapshots: SearchResultSnapshots;
  vessel: VesselData;
  snapshot: VesselSnapshot;
  updateManualData: Callback2<VesselUUID, ManualVesselData>;
  editPermission: Boolean;
  unmerge: Callback3<VesselUUID, VesselDataSource, boolean>;
  recalculateVessel: Callback<VesselUUID>;
  mergeConflicts: MergeConflict[];
  approve: Callback<MergeConflictID>;
  reject: Callback<MergeConflictID>;
}

interface SearchResultRowState {
  showRawData: boolean;
  showSnapshot: boolean;
  showEditForm: boolean;
  showMergeConflicts: boolean;
}

const sourceIsDefined = (
  s: SearchResultSnapshot<VesselDataSource> | undefined
): s is SearchResultSnapshot<VesselDataSource> => !!s;

type VesselDataSourceContextMenuAction = "remove" | "split";

function booleanToString(b?: boolean): string {
  if (b === undefined) return "";
  else if (b) return "Yes";
  else return "No";
}

function VesselDataSourceContextMenu(props: {
  unmerge: Callback<VesselDataSourceContextMenuAction>;
}) {
  const [isOpen, setOpen] = React.useState<boolean>(false);

  const anchorEl = React.useRef(null);

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <IconButton
        ref={anchorEl}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="source-menu"
        anchorEl={anchorEl.current}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        PaperProps={{ style: { width: 200 } }}
      >
        <MenuItem
          key="remove"
          onClick={() => {
            props.unmerge("remove");
            handleClose();
          }}
        >
          Remove
        </MenuItem>
        <MenuItem
          key="split"
          onClick={() => {
            props.unmerge("split");
            handleClose();
          }}
        >
          Split off
        </MenuItem>
      </Menu>
    </div>
  );
}

export class SearchResultRow extends Component<
  SearchResultRowProps,
  SearchResultRowState
> {
  constructor(props: SearchResultRowProps) {
    super(props);
    this.state = {
      showRawData: false,
      showSnapshot: false,
      showEditForm: false,
      showMergeConflicts: false
    };
  }

  private columns: Column<SearchResultSnapshot<VesselDataSource>>[] = [
    {
      columns: [
        {
          id: "contextMenu",
          width: 65,
          accessor: d => (
            <VesselDataSourceContextMenu
              unmerge={action =>
                this.props.unmerge(d.id, d.dataSource, action === "split")
              }
            />
          )
        },
        {
          id: "dataSource",
          accessor: d => d.dataSource,
          width: 65
        }
      ]
    },
    {
      columns: [
        {
          id: "name",
          accessor: d => d.name,
          width: 250
        },
        {
          id: "mmsi",
          Header: "MMSI",
          accessor: d => d.mmsi
        },
        {
          id: "imo",
          Header: "IMO",
          accessor: d => d.imo
        },
        {
          id: "eni",
          Header: "ENI",
          accessor: d => d.eni
        },
        {
          id: "uscg",
          Header: "USCG",
          accessor: d => d.uscg
        },
        {
          id: "callSign",
          Header: "Callsign",
          accessor: d => d.callSign
        },
        {
          id: "shipType",
          Header: "Ship type",
          accessor: d => d.shipType // shipType cannot be updated via api
        },
        {
          id: "statCodeDerived",
          Header: "Stat code",
          accessor: d => d.statCode
        },
        {
          id: "maxDraught",
          Header: "Max draught",
          accessor: d => d.maxDraught
        },
        {
          id: "lengthOverall",
          Header: "LengthOverall",
          accessor: d => d.lengthOverall
        },
        {
          id: "beam",
          Header: "Beam",
          accessor: d => d.beam
        },
        {
          id: "vesselRole",
          Header: "VesselRole",
          accessor: d => d.vesselRole
        },
        {
          id: "aisPositionAllowedReason",
          Header: "Ais allowed reason",
          accessor: d => d.aisPositionAllowedReason
        },
        {
          id: "comment",
          Header: "Comment",
          accessor: "comment"
        },
        {
          id: "capacityContainers",
          Header: "TEU",
          accessor: "capacityContainers"
        },
        {
          id: "isDeepSeaVessel",
          Header: "Is Deep Sea Vessel",
          accessor: d => booleanToString(d.isDeepSeaVessel)
        },
        // these empty cells are only for the layout.
        {
          Header: "Created",
          id: "created"
        },
        {
          Header: "Last Updated",
          id: "lastUpdated"
        }
      ]
    }
  ];

  private closeModal = () => {
    this.setState({
      showRawData: false,
      showSnapshot: false,
      showEditForm: false,
      showMergeConflicts: false
    });
  };

  render() {
    const {
      searchResultSnapshots,
      vessel,
      snapshot,
      mergeConflicts
    } = this.props;

    return (
      <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        {this.state.showRawData && (
          <RawDataModal rawData={vessel} onClose={this.closeModal} />
        )}
        {this.state.showSnapshot && (
          <ViewSnapshotModal
            id={vessel.id}
            snapshot={snapshot}
            onClose={this.closeModal}
            recalculateVessel={this.props.recalculateVessel}
          />
        )}
        {this.state.showEditForm && (
          <EditManualDataModal
            rawData={vessel}
            onClose={this.closeModal}
            updateManualData={this.props.updateManualData}
          />
        )}
        {this.state.showMergeConflicts && (
          <MergeConflictsModal
            mergeConflicts={mergeConflicts}
            onClose={this.closeModal}
            approve={this.props.approve}
            reject={this.props.reject}
          />
        )}
        <ReactTable
          data={searchResultSnapshots.filter(sourceIsDefined)}
          columns={this.columns}
          minRows={1}
          sortable={false}
          showPagination={false}
          getTheadProps={() => ({ style: { display: "none" } })}
          getTfootProps={() => ({ style: { display: "none" } })}
          getTheadGroupProps={() => ({ style: { display: "none" } })}
        />
        {this.props.mergeConflicts !== undefined &&
          this.props.mergeConflicts.length !== 0 && (
            <div
              className={styles.viewRawData}
              onClick={() => this.setState({ showMergeConflicts: true })}
            >
              Merge conflicts
            </div>
          )}
        <div
          className={styles.viewRawData}
          onClick={() => this.setState({ showRawData: true })}
        >
          Raw data
        </div>
        <div
          className={styles.viewRawData}
          onClick={() => this.setState({ showSnapshot: true })}
        >
          Public snapshot
        </div>
        <Link to={"/history/" + vessel.id} className={styles.viewRawData}>
          History
        </Link>
        {this.props.editPermission && (
          <div
            className={styles.viewRawData}
            onClick={() => this.setState({ showEditForm: true })}
          >
            Edit manual data
          </div>
        )}
      </div>
    );
  }
}
