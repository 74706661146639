import { NoticeState } from "./notice.interfaces";
import { NoticeAction } from "./notice.actions";

export const emptyNoticeState: NoticeState = null;

export function noticeReducer(
  state: NoticeState = emptyNoticeState,
  action: NoticeAction
): NoticeState {
  switch (action.type) {
    case "SHOW_NOTICE":
      return { ...state, message: action.message, error: action.error };
    case "HIDE_NOTICE":
      return null;
    default:
      return state;
  }
}
