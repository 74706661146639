import { AccessLevel } from "./permissions.interfaces";
import { createRequest } from "../../util/Login";

export function getPermissions(): Promise<AccessLevel[]> {
  return fetch(`/user/permissions`, createRequest("GET"))
    .then(response => response.json())
    .catch(e => {
      console.warn(e);
      return [];
    });
}
