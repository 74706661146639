import { combineEpics, Epic, ofType } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import {
  LOAD_PERMISSIONS,
  LoadPermissions,
  loadPermissionsResponse,
  PermissionsAction
} from "./permissions.actions";
import { getPermissions } from "./permissions.fetch";

const getPermissionsEpic: Epic<PermissionsAction> = action$ =>
  action$.pipe(
    ofType<PermissionsAction, LoadPermissions>(LOAD_PERMISSIONS),
    mergeMap(action => getPermissions()),
    map(loadPermissionsResponse)
  );

export const permissionsEpic = combineEpics(getPermissionsEpic);
