import { VesselData } from "../../domain/vesseldata/VesselData";
import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { EditManualDataForm } from "./EditManualDataForm";
import { Callback2 } from "../../util/Callback";
import { VesselUUID } from "../../domain/VesselId";
import { ManualVesselData } from "../../domain/vesseldata/ManualVesselData";

type EditManualDataModalProps = Readonly<{
  rawData: VesselData;
  onClose: () => void;
  updateManualData: Callback2<VesselUUID, ManualVesselData>;
}>;

export class EditManualDataModal extends Component<EditManualDataModalProps> {
  render() {
    const { rawData, onClose, updateManualData } = this.props;
    return (
      <div>
        <Modal isOpen={!!this.props.rawData} size="lg">
          <ModalHeader toggle={onClose}>Edit vessel manual data</ModalHeader>
          <ModalBody>
            <EditManualDataForm
              manualData={rawData.manual ? rawData.manual : { data: {} }}
              vesselId={rawData.id}
              updateManualData={updateManualData}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
