import { tokenStorage } from "../components/Login/TokenStorage";

export function createRequest(method: string, body?: string) {
  const headers = createHeaders(tokenStorage.getToken());

  const request: RequestInit = {
    method: method,
    headers: body ? { ...headers, "Content-Type": "application/json" } : headers
  };

  return body ? { ...request, body: body } : request;
}

export function createHeaders(authToken?: string) {
  const headers = {
    Accept: "application/json"
  };

  return authToken
    ? { ...headers, Authorization: `Bearer ${authToken}` }
    : headers;
}
